/**
 * Beneficiary Form Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to add beneficiaries
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import checkValidity from '../../../utils/checkValidity';
import { FieldValue, SelectDropDown } from '../../CustomerManagementModule/components';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import removeIcon from '../../../assets/deleteTrash.svg';
import { Row, Col, Button } from 'react-bootstrap';
import Rules from '../PersonalInfo/Rules';
import styles from '../PersonalInfo/styles';
import { WInput, WIcon, SSNComponent, VictoryDatePicker } from '../../../common';
import '../PersonalInfo/style.css';
import '../MarketNAO/MarketNAOStyles.css';
import downArrow from 'assets/down-arrow.png';
import { getTokenFromSession } from 'shared/Helpers/Utils';
import {communityPropertyStateCodes, communityPropertyStateNames} from './consts'
import { validateSSNWithoutNANValidation } from 'utils/utils';
import { getValidValueOf } from 'commonHelper/CommonHelperFunctions';
import { SessionStorageHelper } from 'utils/sessionHelper';

const assignObj = obj => {
    return obj;
};
const alignSelectAccOpt = {};
const marketAlignSelectAccOpt = { };
const dateFormatText = 'MM/DD/YYYY';
const distributionExceededMsg = 'Distribution Percentanges must total to 100%';
const removeBeneficiaryText = 'Remove';
const DOBErrorMsg = "Please select Beneficiary's Date of Birth";

class BeneficiaryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: {},
            masterDataLoadedForBeneficiary: false,
            primaryBen: false,
            distributionExceeded: false,
            distributionErrorShown: false,
            showRelationshipDropdown:false,
            hasRelationshipOption: false,
            // relationshipIdDropDown:[],
            relationshipId:'',
            beneficiaryType:'',
            isMarketNAOFlow: false,
            isBrokerageBenSsnError: false
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.ssnBenRef = React.createRef();
        this.handleOut = this.handleOut.bind(this);
        this.wrapperRef = React.createRef();

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, distributionPercentageError, handleDistributionErrorMsg ='', benType, hasRelationshipOption = false, isMarketNAOFlow = false } = nextProps;

        if (!prevState.retrievedState && savedState) {
            if(isMarketNAOFlow){
                return {
                    ...savedState,
                    retrievedState: true,
                    distributionExceeded: false,
                    hasRelationshipOption: true,
                    beneficiaryType:savedState.beneficiaryType == undefined ? 'individuals' : savedState.beneficiaryType,
                    isMarketNAOFlow:true,
                    // when page is loaded initally dont show any error msg 
                    isBrokerageBenSsnError: false
                };
            }else{
                return {
                    ...savedState,
                    retrievedState: true,
                    distributionExceeded: false,
                };
            }
        }
        if (distributionPercentageError) {
            handleDistributionErrorMsg(benType);
            return { distributionExceeded: true };
        }

        if(hasRelationshipOption){
            return {hasRelationshipOption : true}
        }

        return {};
    }

    componentDidMount() {
        // const { masterDataLoadedForBeneficiary } = this.state;
        // if (!masterDataLoadedForBeneficiary) {
        const payload = [];
        const { masterLookupStateData, getCompositeLookUpData,getCompositeData } = this.props;
        const compositePayloadData = ['ben_type', 'custd_relation', 'rel_to_custd'];
        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            if (this.props && masterLookupStateData && !masterLookupStateData[tempkey.toString()]) {
                payload.push(tempkey);
            }
        }
       
         if(SessionStorageHelper.getItem('msr_dts')){
            getCompositeData({ data: { datakey: payload },
                token: getTokenFromSession() ,
                marketNAOFlow:"marketNaoFlow"})
        }
        //getCompositeLookUpData(payload);
        document.addEventListener('mousedown', this.handleOut, true);
        // }
    }


    isExistInCommunityPropertyState = (currentUserState) => {
        return( communityPropertyStateNames.includes(currentUserState) || 
         communityPropertyStateCodes.includes(currentUserState))
     }

    

    componentDidUpdate (){

        const userMaritalStatusMarried = this.props.marketNAOReducerData?.marketNAOInfo?.personalInfo?.primary?.maritalStatus != undefined ? 
            typeof(this.props.marketNAOReducerData.marketNAOInfo?.personalInfo?.primary?.maritalStatus) == 'string' ?
            this.props.marketNAOReducerData.marketNAOInfo?.personalInfo?.primary?.maritalStatus.toLowerCase() : '' : ''

        const userPhysicalAddressState =this.props.marketNAOReducerData?.marketNAOInfo?.addressInfo?.primary?.physicalAddress?.state != undefined ? 
            typeof(this.props.marketNAOReducerData.marketNAOInfo.addressInfo.primary.physicalAddress.state) == 'string' ?
            this.props.marketNAOReducerData.marketNAOInfo.addressInfo.primary.physicalAddress.state.toLowerCase() : '':''

        const blockUser = (userMaritalStatusMarried == "married" || userMaritalStatusMarried == "m" ) &&
                        this.isExistInCommunityPropertyState(userPhysicalAddressState)


        if( blockUser && this.props.benType === 'P' && this.state.relationToAccHolder != undefined){ //if the member is married and in shortlisted state and is for a primary beneficiary addition

            if(this.props.countPrimary == 1 ){
                if(this.state.relationToAccHolder == "non_spouse"){
                    this.props.setDisableNextButton(true)
                    this.props.setCommunityPropertyStateRuleVoilated(true)
                    this.resetStateValues(blockUser)
                }else{
                    if(this.state.relationToAccHolder == "spouse" && (parseFloat(this.state.distributionPercentage) == 100)){
                        // enable next only for spouse bene addition with 100% distribution % else disable next 
                        this.props.setDisableNextButton(false)
                    }else{
                        this.props.setDisableNextButton(true)
                    }
                }
            }
            else{
                // disable next if blockUser selects more than one beneficiary
                this.props.setDisableNextButton(true)
            }
        }
    }


    removeBeneficiary = () => {
        const { index, sendToBeneficiaryParentToRemove, benType } = this.props;
        sendToBeneficiaryParentToRemove(index, benType, this.state.relationshipId);
    };

    handleBlur = eventKey => e => {
        const { errorMsg } = this.state;
        let { distributionPercentage } = this.state;

        let err = '';
        const rulesEventKey = eventKey === 'emailAdd' ? 'benEmailAdd' : eventKey;
        err = Rules[rulesEventKey.toString()]
            ? checkValidity({
                  rules: Rules[rulesEventKey.toString()],
                  value: e.target.value,
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        if (eventKey === 'distributionPercentage') {
            const { distributionExceeded } = this.state;
            if (eventKey === 'distributionPercentage') {
                // const validDistributionPercentage = inputCheck(e.target.value);
                // if (validDistributionPercentage === null || '') return;
                distributionPercentage = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '0.00'; // validInitialInvestment!=="0" ? Number(validInitialInvestment).toFixed(2):"0.00";
            }
            if (distributionExceeded && e.target.value !== '') {
                this.setState({
                    distributionExceededMsg: errorMsg.distributionPercentage,
                    distributionExceeded: false,
                });
            } else {
                this.setState({
                    distributionExceededMsg: err,
                    distributionExceeded: false,
                });
            }
        }
        this.setState({ errorMsg, distributionPercentage });
    };

    resetSSN = () => {
        this.ssnBenRef.current.state.ssnOne = '';
        this.ssnBenRef.current.state.ssnTwo = '';
        this.ssnBenRef.current.state.ssnThree = '';
        this.ssnBenRef.current.state.isSSNOneError = false;
        this.ssnBenRef.current.state.isSSNTwoError = false;
        this.ssnBenRef.current.state.isSSNThreeError = false;
        this.ssnBenRef.current.state.errorOneText= '';
        this.ssnBenRef.current.state.errorTwoText= '';
        this.ssnBenRef.current.state.errorThreeText= ''
    }
    
    handleChange = eventKey => e => {
        let targetValue = e.target.value;
        if (eventKey === 'firstName' || eventKey === 'lastName' || eventKey === 'middleName') {
            targetValue = targetValue.replace(/[!@$%^*#(),?":;{}_+|<>='~`\[\]\\]/g, '');
        } else if (eventKey === 'emailAdd') {
            targetValue = targetValue.replace(/\s/g, '');
        }

        const { errorMsg } = this.state;
        let err = '';
        const rulesEventKey = eventKey === 'emailAdd' ? 'benEmailAdd' : eventKey;
       
        if(e.target.value !=undefined && e.target.value !=null && e.target.value !="")
        {
            err = Rules[rulesEventKey.toString()]
            ? checkValidity({
                  rules: Rules[rulesEventKey.toString()],
                  value: targetValue,
              })
            : '';
        }

        errorMsg[eventKey.toString()] = err;
        if (eventKey === 'distributionPercentage') {
            // const targetValue = e.target.value;
            const values = e.target.value ? e.target.value.split('.') : [];
            const isInvalidInput = targetValue.match(/[a-z!@$%^*#(),?":;{}_+|<>='~` /&-\[\]\\]/gi);
            let { distributionPercentage = '' } = this.state;
            if ((isInvalidInput && isInvalidInput.length > 0) || values.length > 2) {
                distributionPercentage = this.state.distributionPercentage || '';
            } else if (values.length === 2 && values[1].length > 2) {
                distributionPercentage = targetValue.substr(0, targetValue.length - 1);
            } else {
                distributionPercentage = e.target.value;
            }
            e.target.value = distributionPercentage;
        }

        if (eventKey === 'beneficiaryType') {
            this.setState({
                [eventKey]: e.target.value,
                lastName: '',
                beneficiaryDOB: '',
                middleName: '',
                emailAdd: '',
                firstName: '',
                distributionPercentage: '',
                relationToAccHolder: '',
                errorMsg: {},
            });
            this.resetSSN()
        } else {
            this.setState({ [eventKey]: targetValue, errorMsg });
        }
    };

    handleDateChange = (dateValue, eventKey) => {
        const { errorMsg } = this.state;
        // const { isChild } = this.props;
        let err = '';
        // let dateVal = dateValue;
        // if (isChild && moment(dateValue) > moment().subtract(1, 'days')) {
        //     dateVal = moment().subtract(1, 'days');
        // }
        const formatedDate = moment(dateValue).format(dateFormatText);
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: formatedDate,
              })
            : '';

        errorMsg[eventKey.toString()] = err;
        this.setState({ [eventKey]: formatedDate, errorMsg });
    };

    handleDateBlur = (dateValue, eventKey) => {
        const { errorMsg } = this.state;
        // const { isChild } = this.props;
        // let dateVal = dateValue;
        // if (isChild && moment(dateVal) > moment().subtract(1, 'days')) {
        //     dateVal = moment().subtract(1, 'days');
        // }
        const formatedDate = moment(dateValue).format(dateFormatText);
        const err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: formatedDate,
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        this.setState({ [eventKey]: formatedDate, errorMsg });
    };

    handleChangeDOB = (eventKey, e) => {
        const { errorMsg } = this.state;
        let err = '';
        if (e.target.value) {
            err = Rules[eventKey.toString()]
                ? checkValidity({
                      rules: Rules[eventKey.toString()],
                      value: e.target.value,
                  })
                : '';
        }
        errorMsg[eventKey.toString()] = err;
        this.setState({ [eventKey]: e.target.value, errorMsg });
    };

    getValidValueForError = (valid,err) =>
    {
        if (!valid) {
            if (typeof err === 'boolean') {
                valid = err === true;
            } else if (typeof err === 'string') {
                valid = err !== '';
            } else {
                valid = err === null;
            }
        }
        return valid
    }

    handleErrorMessageText  = (isChild,errorMsg,toFocus,childText,stateData) =>
    {
        if (isChild) {
            if (errorMsg.firstName) {
                toFocus = `${childText}FirstName`;
            } else if (errorMsg.lastName) {
                toFocus = `${childText}LastName`;
            } else if (errorMsg.beneficiarySSN) {
                toFocus = `childssnOne`;
            } else if (errorMsg.beneficiaryDOB) {
                toFocus = `${childText}BeneficiaryDOB`;
            } else if (errorMsg.relationshipToCustodian) {
                toFocus = `${childText}relationshipToCustodian`;
            }
        }

        if (!isChild) {
            if (errorMsg.beneficiaryType) {
                toFocus = `${childText}BeneficiaryType`;
            } else if (stateData.beneficiaryType === 'individuals' && errorMsg.relationToAccHolder) {
                toFocus = `${childText}relationToAccHolder`;
            } else if (errorMsg.distributionPercentage) {
                toFocus = `${childText}DistributionPercentage`;
            } else if (errorMsg.firstName) {
                toFocus = `${childText}FirstName`;
            } else if (stateData.beneficiaryType === 'individuals' && errorMsg.lastName) {
                toFocus = `${childText}LastName`;
            } else if (stateData.beneficiaryType === 'individuals' && errorMsg.beneficiaryDOB) {
                toFocus = `${childText}BeneficiaryDOB`;
            }
        }

        return toFocus;
    }
    
    validateErrorMessage = (errorMsg,isChild) =>
    {
        return (!errorMsg.beneficiaryType &&
            !errorMsg.distributionPercentage &&
            !errorMsg.firstName &&
            !errorMsg.beneficiaryDOB &&
            !errorMsg.beneficiarySSN &&
            !errorMsg.emailAdd &&
            !isChild);
    }

    validateFields = () => {
        const { isChild, benType, index } = this.props;
        const { errorMsg } = this.state;
        const stateData = this.state;
        let valid = false;
        // const totalDistributionPercentage = distribution.reduce((a,b) => a+b,0);
        const childFileds = ['relationshipToCustodian', 'firstName', 'lastName', 'beneficiaryDOB', 'beneficiarySSN'];
        const irafields = [
            'beneficiaryType',
            'relationToAccHolder',
            'distributionPercentage',
            'firstName',
            'lastName',
            'beneficiarySSN',
            stateData.beneficiaryType === 'other_individuals' ? 'beneficiaryOtherDOB' : 'beneficiaryDOB',
            // 'emailAdd',
        ];
        const fields = isChild ? childFileds : irafields;
        fields.forEach(field => {
            const err = Rules[field.toString()]
                ? checkValidity({
                      rules: Rules[field.toString()],
                      value: stateData[field.toString()] === 'Error' ? '' : stateData[field.toString()],
                  })
                : '';

            errorMsg[field.toString()] = err;

            valid = this.getValidValueForError(valid,err);
            // if (!valid) {
            //     if (typeof err === 'boolean') {
            //         valid = err === true;
            //     } else if (typeof err === 'string') {
            //         valid = err !== '';
            //     } else {
            //         valid = err === null;
            //     }
            // }
        });
        if (errorMsg.beneficiaryDOB && isChild) {
            errorMsg.beneficiaryDOB = DOBErrorMsg;
        }

        if (this.validateErrorMessage(errorMsg,isChild)) {
            if (
                (stateData.beneficiaryType === 'individuals' && !errorMsg.relationToAccHolder && !errorMsg.lastName) ||
                stateData.beneficiaryType === 'other_individuals'
            ) {
                valid = true;
            }
            if (stateData.beneficiaryType === 'individuals') {
                if (errorMsg.relationToAccHolder || errorMsg.lastName) {
                    valid = false;
                }
            }
        } else if (
            isChild &&
            !errorMsg.firstName &&
            !errorMsg.lastName &&
            !errorMsg.relationshipToCustodian &&
            !errorMsg.beneficiarySSN &&
            !errorMsg.beneficiaryDOB
        ) {
            valid = true;
        } else {
            valid = false;
        }
        // if(!valid && !isChild) {
        //     valid = totalDistributionPercentage!==100;
        // }
        // this.setState({ errorMsg, distributionExceeded:(totalDistributionPercentage!==100) });
        let isSSNerror = false;
        if (isChild && errorMsg.beneficiarySSN) {
            isSSNerror = true;
        }
        if (!isChild && errorMsg.beneficiarySSN) {
            isSSNerror = true;
        }

        let ssnBenData = this.ssnBenRef.current && this.ssnBenRef.current.getSSN();
        let isBrokerageBenSsnError = false

        if(ssnBenData?.ssnOne != '' && ssnBenData?.ssnTwo != '' && ssnBenData?.ssnThree != '' && !validateSSNWithoutNANValidation(ssnBenData.ssnOne, ssnBenData.ssnTwo, ssnBenData.ssnThree)){
            isBrokerageBenSsnError = true
        }


        const childText = getValidValueOf(isChild,'child',`beneficiary${benType}${index}`);
        let toFocus = '';

        toFocus = this.handleErrorMessageText(isChild,errorMsg,toFocus,childText,stateData);
        // if (isChild) {
        //     if (errorMsg.firstName) {
        //         toFocus = `${childText}FirstName`;
        //     } else if (errorMsg.lastName) {
        //         toFocus = `${childText}LastName`;
        //     } else if (errorMsg.beneficiarySSN) {
        //         toFocus = `childssnOne`;
        //     } else if (errorMsg.beneficiaryDOB) {
        //         toFocus = `${childText}BeneficiaryDOB`;
        //     } else if (errorMsg.relationshipToCustodian) {
        //         toFocus = `${childText}relationshipToCustodian`;
        //     }
        // }

        // if (!isChild) {
        //     if (errorMsg.beneficiaryType) {
        //         toFocus = `${childText}BeneficiaryType`;
        //     } else if (stateData.beneficiaryType === 'individuals' && errorMsg.relationToAccHolder) {
        //         toFocus = `${childText}relationToAccHolder`;
        //     } else if (errorMsg.distributionPercentage) {
        //         toFocus = `${childText}DistributionPercentage`;
        //     } else if (errorMsg.firstName) {
        //         toFocus = `${childText}FirstName`;
        //     } else if (stateData.beneficiaryType === 'individuals' && errorMsg.lastName) {
        //         toFocus = `${childText}LastName`;
        //     } else if (stateData.beneficiaryType === 'individuals' && errorMsg.beneficiaryDOB) {
        //         toFocus = `${childText}BeneficiaryDOB`;
        //     }
        // }

        this.setState({ errorMsg, isSSNerror, isBrokerageBenSsnError });
        return { valid, toFocus };
    };

    sendDataToBeneficaryCard = (action = 'defaultVal') => {

        let save = action === 'save'
        let valid = true;
        let toFocus = ''
        // if save button is clicked then dont validate and return true, and if its next button or anything then run validation
        if(save){
            valid = true;
            toFocus = ''
            this.setState({ 
                errorMsg:{},
                distributionExceededMsg: '',
                distributionExceeded: false,
                isSSNerror:false
            });

            let ssnBenData = this.ssnBenRef.current && this.ssnBenRef.current.getSSN();
            this.ssnBenRef.current.onSaveResetStateValues()
           // removing the error messages/boolean values and sending the modified data to the payload   
            if(this.ssnBenRef.current){
                ssnBenData = {
                    ...ssnBenData,
                    isSSNOneError:false,
                    isSSNThreeError:false,
                    isSSNTwoError:false,
                }
            }
              
            return { ...this.state, valid, beneficiarySSN: ssnBenData.fullSSN, ssnBenData, toFocus, errorMsg:{} };
        }else{
            ({ valid, toFocus } = this.validateFields());
            const ssnBenData = this.ssnBenRef.current && this.ssnBenRef.current.getSSN();
            return { ...this.state, valid, beneficiarySSN: ssnBenData.fullSSN, ssnBenData, toFocus };
        }   
    };

    setPrimaryBen = (e, index) => {
        const indVal = e.target.checked ? e.target.name === index : false;
        this.setState({
            primaryBen: indVal,
        });
    };

    handleSSN = val => {
        this.setState({ beneficiarySSN: val });
    };

    renderBeneficiaryFields = (benefTypeOp, relationToAccountHolderList) => {
        const {
            errorMsg,
            beneficiaryType,
            relationToAccHolder,
            distributionPercentage,
            distributionExceeded,
            isMarketNAOFlow
        } = this.state;
        const { isChild, index, benType } = this.props;
        const childText = isChild ? 'child' : `beneficiary${benType}${index}`;
        const arialabelVariable = this.getAriaLabelText(benType, index);
        return (
            !isChild && (
                <>
                <div className='marketbeneficiary'>
                    <FieldValue
                        as="p"
                        id={`${childText}BeneficiaryTypeLabel`}
                        label="Beneficiary Type"
                        value={
                            <SelectDropDown
                                aria-labelledby={`${childText}BeneficiaryTypeLabel`}
                                id={`${childText}BeneficiaryType`}
                                data-name="Beneficiary Type"
                                value={beneficiaryType}
                                onChange={this.handleChange('beneficiaryType')}
                                errortext={errorMsg.beneficiaryType}
                                itemlist={benefTypeOp}
                                disabled={this.state.relationshipId != '' && !this.state.relationshipId.startsWith('new-') ? true : false}
                            />
                        }
                        labelsm={4}
                        valuesm={5}
                        mb={1.5625}
                        noGutters
                        alignSelectAccOpt={isMarketNAOFlow ? marketAlignSelectAccOpt : alignSelectAccOpt}
                    />
                    {beneficiaryType && beneficiaryType !== 'other_individuals' && (
                        <FieldValue
                            as="p"
                            id={`${childText}relationToAccHolderLabel`}
                            label="Relationship to Account Holder"
                            value={
                                <SelectDropDown
                                    aria-labelledby={`${childText}relationToAccHolderLabel`}
                                    id={`${childText}relationToAccHolder`}
                                    data-name="Relationship to Account Holder"
                                    value={relationToAccHolder}
                                    onChange={this.handleChange('relationToAccHolder')}
                                    errortext={errorMsg.relationToAccHolder}
                                    itemlist={relationToAccountHolderList}
                                    disabled={this.state.relationshipId != '' && !this.state.relationshipId.startsWith('new-') ? true : false}
                                />
                            }
                            labelsm={4}
                            valuesm={5}
                            mb={1.5625}
                            noGutters
                            alignSelectAccOpt={isMarketNAOFlow ? marketAlignSelectAccOpt : alignSelectAccOpt}
                        />
                    )}
                    
                        {/* offscreen label for keyboard users */}
                        <h3 className="sr-only">{arialabelVariable}</h3>
                        <WInput
                            label="Distribution Percentage"
                            placeholder="XXX"
                            className="distributionPercentage"
                            name="distributionPercentage"
                            id={assignObj(`${childText}DistributionPercentage`)}
                            type="text"
                            onChange={this.handleChange('distributionPercentage')}
                            value={distributionPercentage || ''}
                            required
                            errortext={distributionExceeded ? distributionExceededMsg : errorMsg.distributionPercentage}
                            labelsm={4}
                            valuesm={5}
                            arialabel={assignObj(`${arialabelVariable} Distribution Percentage`)}
                            maxlength={3}
                            subtype="transaction"
                        />
                    
                    </div>
                </>
            )
        );
    };

    renderChildBeneficiaryFields = (custodianRelationshipList, childText) => {
        const { errorMsg, relationshipToCustodian,isMarketNAOFlow } = this.state;
        const { isChild } = this.props;
        return (
            isChild && (
                <FieldValue
                    as="p"
                    id={`${childText}relationshipToCustodianLabel`}
                    label="Relationship to Custodian"
                    value={
                        <SelectDropDown
                            aria-labelledby={`${childText}relationshipToCustodianLabel`}
                            id={`${childText}relationshipToCustodian`}
                            data-name="Relationship to Custodian"
                            value={relationshipToCustodian}
                            onChange={this.handleChange('relationshipToCustodian')}
                            errortext={errorMsg.relationshipToCustodian}
                            itemlist={custodianRelationshipList}
                        />
                    }
                    labelsm={4}
                    valuesm={5}
                    mb={1.5625}
                    noGutters
                    alignSelectAccOpt={isMarketNAOFlow ? marketAlignSelectAccOpt : alignSelectAccOpt}

                />
            )
        );
    };



    renderRemoveButton = arialabelVariable => {
        const { benType } = this.state;
        const { isChild, isRemoveBtn } = this.props;
        return (
            !isChild &&
            isRemoveBtn && (
                <div className="benBtnWrapper">
                    <button
                        type="button"
                        className="addAnotherFieldBtn rmvBtn"
                        onClick={this.removeBeneficiary}
                        bentype={benType}
                        aria-label={assignObj(`Remove ${arialabelVariable}`)}
                    >
                        <WIcon src={removeIcon} fontawesomestyle={styles.iconImgStyle} alt="" />
                        {removeBeneficiaryText}
                    </button>
                </div>
            )
        );
    };

    getAriaLabelText = (benType, index) => {
        let label = '';
        if (benType === 'P') {
            label = `Primary Beneficiary ${index}`;
        } else if (benType === 'C') {
            label = `Contingent Beneficiary ${index}`;
        } else {
            label = 'Child Beneficiary';
        }
        return label;
    };

    addAccountBtn = () => {
        this.setState({ showRelationshipDropdown: !this.state.showRelationshipDropdown });
    }

    handleOut(event) {
        if (this.state.showRelationshipDropdown == true && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
          this.setState({ showRelationshipDropdown:false });
        }
    }

    populateFormWithRelation = (relation) => e => {
        e.preventDefault();
        // TODO: conver taxid to ssn and send
      
        if(relation.relationshipId == 'clear'){
            this.resetStateValues()
        }
        else{

        this.props.handleFamilyRelationShipInfoDropDown(relation.relationshipId, this.state.relationshipId)


        this.setState({
            relationshipId: relation.relationshipId,
            beneficiaryDOB: relation.m_dateOfBirth || '',
            firstName: relation.firstName,
            lastName: relation.lastName,
            taxId: relation.m_ssnTin || '',
            relationToAccHolder: (relation.familyMembersRelationshipToYou === 'Spouse' ? 'spouse' : 'non_spouse'),
            showRelationshipDropdown:false,
            // distributionPercentage: relation.distribution_Per,
            emailAdd:'',
            beneficiarySSN:relation?.m_ssnTin || '',

        });

        if (relation.m_ssnTin != undefined) {
            this.ssnBenRef.current.state.ssnOne = relation?.m_ssnTin.slice(0,3);
            this.ssnBenRef.current.state.ssnTwo = relation?.m_ssnTin.slice(3,5);
            this.ssnBenRef.current.state.ssnThree = relation?.m_ssnTin.slice(5,9);
            this.ssnBenRef.current.state.isSSNOneError = false;
            this.ssnBenRef.current.state.isSSNTwoError = false;
            this.ssnBenRef.current.state.isSSNThreeError = false;
            this.ssnBenRef.current.state.errorOneText= '';
            this.ssnBenRef.current.state.errorTwoText= '';
            this.ssnBenRef.current.state.errorThreeText= ''
        }else{
            this.ssnBenRef.current.state.ssnOne = '';
            this.ssnBenRef.current.state.ssnTwo = '';
            this.ssnBenRef.current.state.ssnThree = '';
            this.ssnBenRef.current.state.isSSNOneError = false;
            this.ssnBenRef.current.state.isSSNTwoError = false;
            this.ssnBenRef.current.state.isSSNThreeError = false;
            this.ssnBenRef.current.state.errorOneText= '';
            this.ssnBenRef.current.state.errorTwoText= '';
            this.ssnBenRef.current.state.errorThreeText= ''
        }
        }
    }




    resetStateValues = (specialCategoryUser = false) => {

        this.props.resetItemValues(this.state.relationshipId, this.props.benType, specialCategoryUser)

        this.setState(
            {
                errorMsg: {
                  beneficiaryType: '',
                  relationToAccHolder: '',
                  distributionPercentage: '',
                  firstName: '',
                  middleName: '',
                  lastName: '',
                  beneficiaryDOB: ''
                },
                masterDataLoadedForBeneficiary: false,
                primaryBen: false,
                distributionExceeded: false,
                distributionErrorShown: false,
                showRelationshipDropdown: false,
                hasRelationshipOption: true,
                relationshipId: "",
                beneficiaryType: "",
                isMarketNAOFlow: true,
                retrievedState: false,
                distributionPercentage: "",
                relationToAccHolder: "",
                distributionExceededMsg: '',
                firstName: "",
                middleName: "",
                lastName: "",
                beneficiarySSN: "",
                beneficiaryDOB: "",
                taxId:"",
                ssn:"",
                dateOfBirth:"",
                ssnBenData:{
                    ssnOne: "",
                    maskedssnOne: "",
                    ssnTwo: "",
                    maskedssnTwo: "",
                    ssnThree: "",
                    maskedssnThree: "",
                    fullSSN: "",
                    errorOneText: "",
                    errorTwoText: "",
                    errorThreeText: "",
                    readOnlySsnOne: false,
                    readOnlySsnTwo: false,
                    typeSsnOne: "password",
                    typeSsnTwo: "password",
                    retrievedState: false,
                    isSSNOneError: false,
                    isSSNTwoError: false,
                    isSSNThreeError: false,
                    maskedFullSSN: ""
                  }
              }
        )

        this.ssnBenRef.current.state.ssnOne = '';
        this.ssnBenRef.current.state.ssnTwo = '';
        this.ssnBenRef.current.state.ssnThree = '';
        this.ssnBenRef.current.state.isSSNOneError = false;
        this.ssnBenRef.current.state.isSSNTwoError = false;
        this.ssnBenRef.current.state.isSSNThreeError = false;

    }

    render() {
        const {
            errorMsg,
            firstName,
            middleName,
            lastName,
            // beneficiarySSN,
            emailAdd,
            beneficiaryDOB,
            beneficiaryType,
            //  primaryBen,
            ssnBenData,
            isSSNerror,
            isMarketNAOFlow
        } = this.state;
        const { masterLookupStateData, isChild, index, benType } = this.props;
        const {
            ben_type: benTypeMaster,
            custd_relation: custodianRelationshipMaster,
            rel_to_custd: relationToAccountHolderMaster,
        } = masterLookupStateData || {};
        const { value: benTypeMasterValue } = benTypeMaster || {};
        const { value: custodianRelationshipMasterValue } = custodianRelationshipMaster || {};
        const { value: relationToAccountHolderMasterValue } = relationToAccountHolderMaster || {};
        const benefTypeOp = benTypeMasterValue || [];
        const custodianRelationshipList = custodianRelationshipMasterValue || [];
        const relationToAccountHolderList = relationToAccountHolderMasterValue || [];
        const dateofBirthLabel = ' Date of Birth';
        // const primaryBenLabel = 'Set Primary Beneficiary';
        const childText = isChild ? 'child' : `beneficiary${benType}${index}`;
        const arialabelVariable = this.getAriaLabelText(benType, index);

        const {familyRelationShipInfo} = this.props

        const shouldDisableField = this.state.relationshipId != '' && !this.state.relationshipId.startsWith('new-') ? true : false

        const clearObject = {
            relationshipId: "clear",
            familyMembersRelationshipToYou: " ",
            firstName:"----------Clear Data---------"
          }

       // let modifiedDropDown =  [...familyRelationShipInfo, clearObject]


        return (
            <div className="beneficiaryForm">
               
                {this.renderRemoveButton(arialabelVariable)}
                 {/* do not delete this, it is used later  */}
                {/* <div ref={this.wrapperRef}>
                  
                { this.state.hasRelationshipOption && familyRelationShipInfo && familyRelationShipInfo.length > 0 &&
                    <Row className='marketNAOFlowRelationshipDropdown'>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Button className="selectExistingDropdown"  onClick={() => this.addAccountBtn()}> Choose from Existing Relationship <img className="wid25px" src={downArrow} alt="" /></Button>
                            {this.state.showRelationshipDropdown &&  
                                <div className={`${this.state.showRelationshipDropdown && "dropdown_relation"}`}>
                                    { familyRelationShipInfo && 
                                        modifiedDropDown.map((relation, i) => (
                                            <div >
                                                {(this.props.selectedRelationList.includes(relation.relationshipId) == false)  && 
                                            <Row noGutters onClick={this.populateFormWithRelation(relation)} style={{borderBottom:"1px solid gainsboro"}}>
                                                <Col xs={12} sm={6} md={6} lg={6} xl={6} key={i}>
                                                    <p  className="fntN" style={{ margin: "0px" }} >{relation.firstName}
                                                    </p>
                                                </Col>
                                                <Col xs={12} sm={6} md={6} lg={6} xl={6} key={i + 1}>
                                                    {relation.familyMembersRelationshipToYou?
                                                    <p className="fntN" style={{ margin: "0px",fontStyle: "italic" }} >
                                                    {relation.familyMembersRelationshipToYou}
                                                    </p>
                                                    :<p>---</p>}
                                                
                                                </Col>
                                            </Row>
                                            }
                                            </div>
                                        ))
                                    }   
                                </div>
                            }
                        </Col>
                    </Row>   
                }
                </div> */}

                <br />

                <div className={`${isMarketNAOFlow ? 'childboxsection': ''}`}>

                {this.renderBeneficiaryFields(benefTypeOp, relationToAccountHolderList)}
                
                    <WInput
                        label={beneficiaryType === 'other_individuals' ? 'Name' : 'First Name'}
                        placeholder={beneficiaryType === 'other_individuals' ? 'Name' : 'First Name'}
                        className=""
                        name="firstName"
                        id={assignObj(`${childText}FirstName`)}
                        type="text"
                        onChange={this.handleChange('firstName')}
                        value={firstName || ''}
                        required
                        errortext={errorMsg.firstName}
                        labelsm={4}
                        valuesm={5}
                        maxlength={40}
                        // subtype="characters"
                        arialabel={assignObj(`${arialabelVariable} First Name`)}
                        disabled={shouldDisableField}
                    />
               
                {beneficiaryType !== 'other_individuals' && (
                    <>
                        
                            <WInput
                                label="Middle Name"
                                placeholder="Middle Name"
                                optional="true"
                                sublabel={styles.sublabel}
                                name="middleName"
                                id={assignObj(`${childText}MiddleName`)}
                                type="text"
                                onChange={this.handleChange('middleName')}
                                value={middleName || ''}
                                errortext={errorMsg.middleName}
                                labelsm={4}
                                valuesm={5}
                                maxlength={40}
                                // subtype="characters"
                                arialabel={assignObj(`${arialabelVariable} Middle Name`)}
                                disabled={shouldDisableField}
                            />
                        
                       
                            <WInput
                                label="Last Name"
                                placeholder="Last Name"
                                className=""
                                name="lastName"
                                id={assignObj(`${childText}LastName`)}
                                type="text"
                                required
                                onChange={this.handleChange('lastName')}
                                value={lastName || ''}
                                errortext={errorMsg.lastName}
                                labelsm={4}
                                valuesm={5}
                                maxlength={40}
                                // subtype="characters"
                                arialabel={assignObj(`${arialabelVariable} Last Name`)}
                                disabled={shouldDisableField}
                            />
                        
                    </>
                )}
                
                    <SSNComponent
                        ref={this.ssnBenRef}
                        savedstate={ssnBenData}
                        sendfullssn={this.handleSSN}
                        // optional={isUTMA ? 'false' : 'true'}
                        type={childText}
                        isssnerror={isSSNerror}
                        isBrokerageBenSsnError={this.state.isBrokerageBenSsnError}
                        valuesm={7}
                        otherLabelInfo={`${arialabelVariable}`}
                        disabledFirstSnn={this.state.relationshipId != '' && !this.state.relationshipId.startsWith('new-') && this.state.taxId != undefined && this.state.taxId != '' ? true : false}
                        disabledTwoSnn={this.state.relationshipId != '' && !this.state.relationshipId.startsWith('new-') && this.state.taxId != undefined && this.state.taxId != '' ? true : false}   
                        disabledThirdSnn={this.state.relationshipId != '' && !this.state.relationshipId.startsWith('new-') && this.state.taxId != undefined && this.state.taxId != '' ? true : false}
                        // handleValidationsOnBlur = {false}
                        showSsn={true}
                    />
                

                {(isChild || beneficiaryType === 'individuals') && (
                    <VictoryDatePicker
                        id={assignObj(`${childText}BeneficiaryDOB`)}
                        value={beneficiaryDOB?.replace(/-/g, '/') || ''}
                        onDateChange={this.handleDateChange}
                        // onDateBlur={this.handleDateBlur}
                        argument1="beneficiaryDOB"
                        label={dateofBirthLabel}
                        errortext={errorMsg.beneficiaryDOB}
                        isChildBeneficiary={isChild}
                        // dobValidation
                        maskedDateOfBirth={beneficiaryDOB?.includes('X') ==  true ? true : false}
                        DOBErrorMsg={DOBErrorMsg}
                        required={beneficiaryType !== 'other_individuals'}
                        datePickerModule="dobNAO"
                        ariaLabelledBy={assignObj(`${childText}BeneficiaryDOB`)}
                        ariaDescribedBy="BeneficiaryFormInfostartDate"
                        ariaLabel={`${arialabelVariable} Date of birth`}
                        // disabled={role !== 'Guest' && role !== 'Prospect'}
                        disabled={shouldDisableField}

                    />
                )}

                {!isChild && (
                    <>
                        
                            <WInput
                                label="Email"
                                // placeholder="abc@gmail.com"
                                id={assignObj(`${childText}emailAdd`)}
                                name="emailAdd"
                                type="email"
                                onChange={this.handleChange('emailAdd')}
                                onInput={assignObj(e => {
                                    e.target.value = e.target.value.replace(/\s/g, '');
                                })}
                                required
                                value={emailAdd || ''}
                                errortext={errorMsg.emailAdd}
                                labelsm={4}
                                valuesm={5}
                                maxlength={30}
                                optional="true"
                                arialabel={`${arialabelVariable} Email Address`}
                            />
                        
                    </>
                )}
                {this.renderChildBeneficiaryFields(custodianRelationshipList, childText)}
                </div>
            </div>
        );
    }
}
BeneficiaryForm.propTypes = {
    savedState: PropTypes.instanceOf(Object),
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    isChild: PropTypes.bool,
    isRemoveBtn: PropTypes.bool,
    index: PropTypes.string,
    distributionPercentageError: PropTypes.bool,
    handleDistributionErrorMsg: PropTypes.func,
    sendToBeneficiaryParentToRemove: PropTypes.func,
    benType: PropTypes.string,
    isUTMA: PropTypes.bool,
};

BeneficiaryForm.defaultProps = {
    savedState: {},
    masterLookupStateData: {},
    getCompositeLookUpData: () => {},
    isChild: false,
    isRemoveBtn: true,
    index: '',
    distributionPercentageError: false,
    handleDistributionErrorMsg: () => {},
    sendToBeneficiaryParentToRemove: () => {},
    benType: '',
    isUTMA: false,
};
export default BeneficiaryForm;
