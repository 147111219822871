import envConfig from "env/EnvConfig";

export const skipFistaLogin = false;

export const EMPLOYER_YEAR_TEXT = ['Employer', '-'];
// export const EMPLOYER_CONTRIBUTIONS = 'Employer Contributions';
export const EMPLOYER_CONTRIBUTIONS = 'Contribution for IRA Account';
// Transaction module constants - label

const ACCOUNT_SELECTION_TEXT_VALUE = 'Account Selection';

export const ACCORDIAN_HEADING = 'Select your Mutual Fund';
export const MINIMUM_TEXT = 'Minimum';
export const FUND_MINMAX_TEXT = 'Min. Amount with Auto Investing';
export const FUND_NAV_TEXT = '1-Day NAV Change';
export const FUND_LAST_NAV_TEXT = 'NAV';
export const FUND_WEEK_MIN_MAX_TEXT = '52 Week Range';
export const WEEK_MIN_MAX_TEXT_EXCHANGE = '52-week min/max';
export const FUND_RISK = 'Risk:';
export const MANDATORY_TEXT = '* All fields are required except mentioned optional';

export const CHOOSE_BELOW = 'If you are selecting a monthly investment, please read this important notice:';
export const CHOOSE_BELOW1 =
    'While choosing your funding options below, for monthly investments the start day must be at least 4 business days from today. If the day of the month you select is on a weekend or holiday, then the transaction will occur on the next business day.';

export const INVALID_FIS_TOKEN = 'Invalid fis token. Try after authenticating with FIS.';

export const FUND_NAME = 'Fund Name';
export const FUNDING_OPTIONS = 'Funding Options';
export const PURCHASE_TYPE = 'Purchase Type';
export const MSR_INVALID_CREDENTIAL = {
    message: 'Invalid MSR credential',
};
export const MONTHLY_INVESTMENT = 'Monthly Investment';
export const INITIAL_INVESTMENT = 'Initial Investment';
export const ONE_TIME_INVESTMENT_VALUE = 'One-time Investment';
export const SUBSEQUENT_INVESTMENT_VALUE = 'Subsequent Investment';
export const STATE_DATE = 'Monthly Investment Start Date';
export const NEW_FUND = 'New Fund'
export const ACTION = 'Action';
export const N_AND_A = 'N/A';
export const MINIMUM_DOLLAR = 'Minimum $';
export const REMOVE_TEXT = 'Remove';
export const TOTAL_TEXT = 'Total';
export const compareAlertMsg = 'Please select minimum 2 or maximum 4 funds to compare';
export const WIRE_TRANSFER_ACC_DETAILS_TEXT =
    'The transaction will be processed on confirmation of wire transfer details. Please wait for 3-5 business days for completion of your transaction.';
export const REINVEST_DIVIDENTS = 'Do you want to Reinvest in Dividends and Capital Gains?';
export const REINVEST_DIVIDENTS_SUBTEXT =
    'You will not have option to receive cash when you choose Check Order or Wire Transfer';
export const ADD_BANK_ACCOUNT = 'Add Bank Account';
export const ADD_BANK = 'Add Bank';
export const SUCCESS = 'Success';
export const WIRE_TRANSFER_TEXT = 'Wire Transfer';
export const PERSONAL_CHECK_TEXT = 'Personal Check';
export const PERSONAL_CHECK_VALUE = '01';
export const WIRETRANSFER_VALUE = '03';
export const BANK_VALUE = '05';
export const CLOSE_PARENTHESES = ')';
export const VIEW_FUND_LIST_TEXT = 'View fund details';
export const SEPARATOR_SYMBOL_DASH = '-';
export const SELL_DISCLAIMER =
    'The Amount to be sold is an approximate transaction amount and is subject to the next available market closing price.';
export const SELECT_FUND_TEXT = 'Select the Fund to Invest';
export const SELECT_FUND_FROM_LIST_BELOW = 'Select a fund from the list below.';
export const PERCENT_OF_AMOUNT = '% of Amount';
export const OPEN_PARENTHESES = '(';
export const SELECT_A_FUND_TEXT = 'Select a Fund';
export const PERCENT_OF_SHARES = '% of Shares';
export const INCREASE_IN_PERCENTAGE_TEXT = 'Increase in percentage';
export const FUND_PERCENTAMT_SHARE_VALUE = 'percentamtshare';
export const FUND_LIST_TEXT = 'Fund List';
export const EXCHANGE_INVESTMENT_SELECTION_TITLE_TEXT =
    'Investment Selections: Exchange Transactions | Victory Capital';
export const EXCHANGE_FROM = 'Exchange from';
export const EXCHANGE_DISCLAIMER =
    'The Amount to be exchanged is an approximate transaction amount and is subject to the next available market closing price.';
export const ERR_TEXT_ALL_SHARES_AMOUNT = `Your liquidation amount is equal to your current fund value. Please select “All Shares” to ensure a successful trade.`;
export const ERRWORTHAMOUNT_LESS_THAN_ONE_DOLLAR =
    'Please enter a percentage that accounts for more than $1.00 of your account balance.';
export const EXCHANGE_INVESTMENT_SELECTION_FROM_TITLE_TEXT =
    'Fund Your Account: Exchange Transactions | Victory Capital';
export const ERRPERCENTVALID_SHARE = 'Please enter a valid percentage of shares';
export const ERRPERCENTAGE_SHARE = 'Please enter the percentage of shares';
export const CURRENT_SHARES = 'Current Shares';
export const ERRPERCENTVALID = 'Please enter a valid percentage of amount';
export const ERRPERCENTAGE = 'Please enter the percentage of amount';
export const DECREASE_IN_PERCENTAGE_TEXT = 'Decrease in percentage';
export const CHECK_ORDER_TEXT = 'Check';
export const CHECK_ORDER_VALUE = 'checkOrder';
export const CHECK_CLICK_HANDLER = 'check';
export const WIRETRANSFER_CLICK_HANDLER = 'wireTransfer';
export const NEW_BANK_TEXT = 'newBank';
export const EXISTING_BANK_TEXT = 'existingBank';
export const HANDLEERROR_CLOSE = 'close';
export const HANDLEERROR_HIDE = 'hide';
export const FUND_YOUR_ACCOUNT_TEXT = 'Fund Your Account';
export const FUND_YOUR_ACCOUNT_DESC_TEXT = 'Choose how to fund your account.';
export const LIQ_FUND_YOUR_ACCOUNT_DESC_TEXT = 'Select destination where trade proceeds should go.';
export const CONTRIBUTION_SUB_TEXT = 'This is sample content paragraph';
export const CONTRIBUTION_IRA = 'Contribution for IRA Account';
export const OR_TEXT = '[or]';
export const ONLINE_METHOD = 'Online Method';
export const OFFLINE_METHOD = 'Offline Method';
export const ONLINE_TEXT = 'Online';
export const OFFLINE_TEXT = 'Offline';
export const WIRE_TRANSFER_MAILING_ADDRESS_TEXT = 'USAA Mutual Funds \n PO Box# 182903 \n Columbus Ohio 43218-2903';
export const CHECK_TEXT =
    'Please make the check payable to USAA Mutual Funds and include the account number of the mutual fund in the memo section. Note: We do not accept 3rd party checks, traveler’s checks and money orders.. The transaction will be processed only after receiving the check. Please wait for 3-5 business days for completion of your transaction.';
export const NO_REINVEST = "No, I don't want to Reinvest";
export const YES_REINVEST = 'Yes, I want to Reinvest';
export const REINVEST_DIVIDENDS = 'You will not have option to receive cash while choose Check Order & Wire Transfer';
export const NEXT_TEXT = 'Next';
export const ACCOUNT_SELECTION_TEXT = ACCOUNT_SELECTION_TEXT_VALUE;
export const EDIT_TEXT = 'Edit';
export const ACCOUNT_NAME_TEXT = 'Account Name';
export const ACCOUNT_NUMBER_TEXT = 'Account Number';
export const FUND_ACCOUNT_NUMBER_TEXT = 'Fund Account Number';
export const ACCOUNT_TYPE_TEXT = 'Account Type';
export const CURRENT_VAL_TEXT = 'Current Value';
export const TOTAL_SHARES_TEXT = 'Total no. of shares';
export const AIP_TEXT = 'Automatic Investment Plan';
export const NO_TEXT = 'No';
export const YES_TEXT = 'Yes';
export const EXISTING_FUND = 'Existing Fund';
export const INVEST_FUND_TEXT = 'Fund Your Investments';

export const ARIA_LABEL = 'aria-label';
export const PRINT_OPENS = 'Print Opens in new tab';
export const OFFLINE_FUND_DETAILS = 'offlineFundDetails';
export const IRA_ACCOUNT = 'IRA Account';
export const PRIMARY_BUTTON = 'PrimaryButton';
export const SECONDARY_BUTTON = 'SecondaryButton';
export const BACK_TEXT = 'Back';
export const PRINT_TEXT = 'Print';
export const NAV_CHANGE_TEXT = 'NAV Change';
export const WINDOW_OPEN_PRINT = 'PRINT';
export const SELECT_PAYMENT_METHOD = 'Kindly select any pay methods.';
export const SELECT_CONTRIBUTION_YEAR = 'Kindly select contribution year.';
export const NOTIFICATION_ERROR_MESSAGE = 'Some unexpected error has occured.';
export const NO_INPUT_VALIDATION_ERROR_MESSAGE = 'Please enter a valid amount';
export const NO_INPUT_VALIDATION_ERROR_MESSAGE_DATE = 'Please enter a valid start date';
export const INITIAL_INVESTMENT_VALIDATION_ERROR_MESSAGE = 'Investment must meet or exceed the fund minimum.';
export const MONTHLY_INVESTMENT_VALIDATION_ERROR_MESSAGE = 'Monthly investment must meet or exceed the fund minimum.';

export const START_DATE_INPUT_VALIDATION_ERROR_MESSAGE = 'Please enter a valid start date.';
export const SELECT_SHARE_TEXT = 'Select the share option for the ';
export const MONTLY_INVESTMENT_MAX_VALIDATION_ERROR_MESSAGE = 'Monthly investment cannot be greater than $50,000';
export const START_DATE_VALIDATION_ERROR_MESSAGE = 'Start date must be at least 2 days from today.';
export const SUBSEQUENT_INVESTMENT = 'Subsequent';
export const PURCHASE_SUCCESS_MESSAGE = 'Your order has been successfully placed.';
export const CONFIRMATION_PAGE = 'Confirmation';
export const CONFIRMATION_MESSAGE_PART1 =
    'Your order has been received and we will begin processing. You will receive confirmation when your order is completed. ';
export const CONFIRMATION_MESSAGE_WIRE_TRANSFER =
    'Please follow the instructions below to complete your order. Your order will be processed once we receive your wire transfer. You will receive confirmation when your order is completed. ';
export const CONFIRMATION_MESSAGE_CHECK =
    'Please follow the instructions below to complete your order. Your order will be processed once we receive your check. You will receive confirmation when your order is completed. ';
export const CONFIRMATION_MESSAGE_PART2 = 'You will receive confirmation when your order is completed. ';
export const CONFIRMATION_MESSAGE_PART3 = 'Your fund account number is ';
export const FUNDING_TYPE = 'Funding Type';
export const END_OF_SENTENCE_PERIOD = '.';
export const TRANSACTION_STANDARD_ERROR_MESSAGE =
    'We are unable to process your order at this time. Please review FISTA for available shares, address holds, and stop codes.';
export const ERROR_PAGE = 'Error';
export const NO_ACCOUNTS_MESSAGE = 'Currently you do not have any accounts to be displayed';
export const SELECT_OTHER_ACCOUNTS_MESSAGE =
    'For the exchange amount you have entered, the amount does not meet the minimum investment amount for any funds. Please adjust your exchange amount to proceed. If you require assistance, please contact Victory Member Service.';
export const DOLLAR_SYMBOL = '$ ';
export const SEPARATOR_SYMBOL = '/';
export const FUND_WORTH_TEXT = 'Available Balance';
export const FUND_APPROX_TEXT = '(Approx.)';
export const FUND_ALL_SHARES_TEXT = 'All Shares';
export const FUND_ALL_SHARE_VALUE = 'allshare';
export const FUND_DOLLAR_AMT_VALUE = 'dollaramt';
export const FUND_PERCENTAMT_VALUE = 'percentamt';
export const WORTHAMOUNT = 'Worth Amount ';
export const SELLINGAMOUNT = 'Selling Amount';
export const ERRAMOUNTVALID = 'Please enter a valid amount';
export const ERRAMOUNT_GREATER_THAN_WORTH = 'Please enter amount less than or equal to available balance';
//export const ERRWORTHAMOUNTVALID = 'Please enter an amount greater than $1 and less than Worth$.';
export const ERRWORTHAMOUNTVALID = 'Please enter an amount greater than $1.';
export const ERRAMOUNT90 = `Your liquidation amount represents a high percentage of your current fund value. Due to price fluctuation you may wish to select “All Shares” to ensure a successful trade.`;
export const ERRAMOUNT = 'Please enter the amount';
export const NUMBER_OF_FUNDS_PER_PAGE = 6;
export const LIQ_NOT_ENOUGH_BALANCE = 'Minimum amount is $1.00';
export const SELLERRAMOUNT90 = `Your liquidation amount represents a high percentage of your total current fund value`;
// Transaction Module const values
export const compositePayload = [
    // 'fund_source',
    'fund_optionsv1',
    'fund_options_subv1',
    // 'bank_acct_type',
    // 'filter_min_inv',
    // 'filter_fund_type',
    // 'filter_risk',
];
export const CONTRIBUTION_OPTIONS = ['Current Year', 'Prior Year'];
export const CONTRIBUTION_OPTIONS_VALUE = ['currYear', 'prevYear'];
export const CURRENT_YEAR_TEXT = ['Current Year'];
export const PREVIOUS_YEAR_TEXT = 'Prior Year';
export const USAA_FUND_COMPANY_NUMBER = { companyNumber: '591' };
export const BEHVIOUR_SMOOTH_VALUE = 'smooth';
export const AVAILABLE_SHARES_TEXT = 'Available Shares';
export const STEPNAME_VALUE = 'stepname';
export const FUNDSOURCE_VALUE = 'Fund Source';
export const START_DATE_FORM_FIELD_VALUE = 'startDate';
export const TRADING_WINDOW_CLOSE_MESSAGE =
    'Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at 4:00 pm ET. You must place your order prior to 3:59 pm ET to be sure your order gets today’s Net Asset Value.';

export const TRADING_WINDOW_HOLIDAY_CLOSURE_MESSAGE =
    'Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at 4:00 pm ET. You are placing an order after market close and will receive the Net Asset Value of next business dayrket Hours for Mutual Funds: You are placing an order after market close and will receive the Net Asset Value of next business day';
export const TRADING_WINDOW_PENDING_OPEN_MESSAGE =
    'Market Hours for Mutual Funds: Mutual Funds orders remain open from 9:30 am ET until the market closes at 4:00 pm ET. You are placing an order during market close and will receive the Net Asset Value of today.';
export const CHECK_LABEL = 'I have read, understand and accept the terms of the summary prospectus for this fund.';
export const STEPS = [ACCOUNT_SELECTION_TEXT_VALUE, 'Investment Selection', 'Deposit', 'Review & Confirm'];
export const ACTIVE_BREADCRUMB = 'Sell';
export const SUBMIT_TEXT = 'Submit';
export const FUND_SELECTION_TEXT = 'Fund Selection';
export const PUR_TEXT = 'pur';
export const INIT_TEXT = 'init';
export const FUND_INVESTMENT_TABLE_CAPTION = 'Fund Investments';
export const SUB_TEXT = 'sub';
export const CANCEL_TRANSACTION_TEXT = 'Cancel Transaction';
export const CANCEL_TRANSACTION_DESCRIPTION_PURCHASE = 'Are you sure you want to cancel Purchase';
export const CANCEL_TRANSACTION_DESCRIPTION_LIQ = 'Are you sure you want to cancel Liquidation';
export const CANCEL_TRANSACTION_DESCRIPTION_EXCHANGE = 'Are you sure you want to cancel Exchange';
export const CANCEL_TRANSACTION_DESCRIPTION = 'Are you sure you want to cancel';
export const QUESTION_MARK = '?';
export const CANCEL_TRANSACTION_DESCRIPTION_GENERAL =
    'You will lose any data you have saved for this transaction. Are you sure you want to proceed?';
export const INIT_MON_TEXT = 'init_mon';
export const SUB_MON_TEXT = 'sub_mon';
// To be deleted - when the data is fetched from APIs
export const WIRE_TRANSFER_DETAILS = [
    {
        label: 'USAA Fund Name',
        value: 'Aggressive Growth Fund',
    },
    {
        label: 'USAA Account Number',
        value: 'xxxx-xxxx-xxxx',
    },
    {
        label: 'Name',
        value: 'William',
    },
    {
        label: 'USAA Mutual Fund Account Number',
        value: 'xxxx-xxxx-xxxx',
    },
];

export const WIRE_TRANSFER_MAILING_ADDRESS_LABEL = 'Mailing to This Address';

export const NO_POSITIONS_TO_SELL_MSG =
    'Due to recent activity, this account does not have any eligible positions to fulfill your request.';

// Identifiying IRA account with Holding Gourp 2 as we don't have proper test data
// This will be modified to Retirement Accounts once we have the proper data
export const IRA_HOLDING_GROUP_NAME = 'Holding group 2';

export const WF_MAILING_ADDRESS = 'wf_mailing_address';
export const IRA_CONTRIBUTION_LIMIT = 'ira_contribution_limit_';

export const OK_TEXT = 'Ok';
export const INSTRUCTIONS_TEXT = 'Please follow the instructions below';
export const EDIT_DIV = 'EditDiv';
export const TRADE_TYPE_TEXT = 'Trade Type';
export const TRADE_TYPE_VALUE = 'tradeType';
export const ACCOUNT_SELECTION = 'accountSelection';
export const VCM_FUNDS_TEXT = 'vcmFunds';
export const MUTUAL_FUNDS_TEXT = 'mutualFund';
export const DIVIDENDS_TEXT = 'dividends';
export const SELLING_FUNDS_TEXT = 'sellingFunds';
export const FUND_ACCOUNT_TEXT = 'fundAccount';
export const CONTRIBUTION_TEXT = 'contribution';
export const BUY_TEXT = 'Buy';
export const EXCHANGE_TEXT = 'Exchange';
export const START_DATE_TEXT = 'Start Date';
export const NOT_ON_FILE_TEXT = '-';
export const TOTAL_INVESTMENT_TEXT = 'Total Investment';
export const BANK_ACCOUNT_TEXT = 'Bank Account';
export const FUND_SOURCE_TEXT = 'Funding Source';
export const INVESTMENT_SELECTION_TEXT = 'Investment Selection';
export const DIVIDEND_CAPITAL_GAINS_TEXT = 'Do you want to Reinvest in Dividends and Capital gains?';
export const FUND_DETAILS_TEXT = 'Selected Fund details';
export const ACCOUNT_INFORMATION = 'Account Information';
export const REVIEW_CONFIRM_TEXT = 'Review and Confirm';
export const EXCHANGE_TRANSACTION_TYPE = 'exchange';
export const TRADITIONAL_IRA = 'Traditional IRA';
export const ROTH_IRA = 'Roth IRA';
export const ROTH = 'roth';
export const SEP_IRA = 'SEP IRA';
export const COVERDELL_EDUCATION_IRA = 'coverdell';
export const ROLLOVER_IRA = 'Rollover IRA';
export const GUARDIAN_IRA = 'Guardian IRA';
export const SIMPLE_IRA_DFI = 'Simple IRA - DFI';
export const SIMPLE_IRA_NON_DFI = 'Simple IRA - Non DFI';
export const SIMPLE_IRA = 'Simple IRA';
export const BENEFICIARY_IRA = 'Beneficiary IRA';
export const BENEFICIARY_ROTH_IRA = 'Roth Beneficiary IRA';
export const OPTIONAL_RETIREMENT_IRA = 'Optional Retirement Program';
export const PENSION_IRA = 'Pension';
export const ROTH_CONVERSION_IRA = 'Roth IRA - Conversion';
export const TRANSACTION_LOAD_MORE = 'Show all Funds';
export const PAD_PAYMENT_METHOD_WARNING =
    'If you have chosen to add a monthly investment plan, you may only fund this order with a bank account.';
export const FWH_TEXT = 'FWH';
export const DAT_TEXT = 'DAT';
export const GROUP_1_TEXT = 'group1';
export const GROUP_2A_TEXT = '';
export const GROUP_2B_TEXT = 'group2b';
export const GROUP_3_TEXT = 'group3';
export const GROUP_4_TEXT = 'group4';
export const GROUP_5_TEXT = 'group5';
export const TYPE_TEXT = 'text';
export const SAVINGS_TEXT = 'Savings';
export const PROSPECTUS_NOT_AVAILABLE = 'Currently, the prospectus is not available for ';
export const L05B_PREMATURE_DISTRIBUTION_ERROR_MESSAGE =
    'Because you are less than 59 ½ years of age, your withdrawal may be subject to taxes and penalties. Please consider alternatives to withdrawing from your IRA. If you are withdrawing funds due to death, divorce, disability or other special circumstances please call a Victory Capital Member Service Representative at 1-800-235-8396.';

export const WARNING_WINDOW_CHECK_WIRE_TRANSFER =
    'The transaction will be processed only after receiving the check.  Please wait for 3 to 5 business days for completion of your transaction.';
// TODO :: Following review confirm data has to be cleaned up not use array of objects
export const REVIEW_CONFIRM_DATA = [
    {
        cardId: 'tradeType',
        cardHeading: TRADE_TYPE_TEXT,
        fields: [
            {
                key: 'TradeType',
            },
        ],
    },
    {
        cardId: 'accountSelection',
        cardHeading: ACCOUNT_SELECTION_TEXT_VALUE,
        fields: [
            {
                key: ACCOUNT_NAME_TEXT,
            },
            {
                key: ACCOUNT_NUMBER_TEXT,
            },
        ],
    },
    {
        cardId: 'vcmFunds',
        cardHeading: 'Select your Mutual Fund',
        fundName: '',
        fields: [
            {
                key: 'Amount',
            },
            {
                key: 'Monthly Investment',
            },
            {
                key: 'Start Date',
            },
        ],
    },
    {
        cardId: 'dividends',
        cardHeading: 'Dividends and Capital Gains Preferences',
        fields: [
            {
                key: 'Do you want to Reinvest in Dividends and Capital gains?',
            },
        ],
    },
    {
        cardId: 'fundAccount',
        cardHeading: 'Fund Your Account',
        fields: [
            {
                key: 'Funding Source',
            },
            {
                key: 'Total Investment',
            },
        ],
    },
    {
        cardId: 'contribution',
        cardHeading: 'Contribution',
        fields: [
            {
                key: 'Contribution for IRA Account',
            },
        ],
    },
];

export const ONLINE_FUND_SOURCE_OBJ = {
    cardId: 'fundAccount',
    cardHeading: 'Fund Your Account',
    fields: [
        {
            key: 'Funding Source',
        },
        {
            key: 'Account Number',
        },
        {
            key: 'Total Investment',
        },
    ],
};

// Liquidation Constants

export const FUND_SOURCE_PAYMENT_METHOD_DETAILS = {
    fundFrom: {
        detailName: 'Deposit',
        description: LIQ_FUND_YOUR_ACCOUNT_DESC_TEXT,
        orSeperator: '[or]',
        accverificationmessage:
            'For your account’s security, you will receive a call momentarily to authenticate yourself.',
        tobeVerified: 'To be verified',
        tobeVerifiedAlert: '!',
        tobeVerifiedflag: true,
        methods: [
            {
                title: 'Offline Method',
                desc: '',
                method: 'offline',
                details: [
                    {
                        text: 'Check',
                    },
                ],
            },
            {
                title: 'Online Method',
                desc: '',
                method: 'online',
            },
        ],
    },
    Deliveryaddress: {
        detailName: 'Delivery Address',
        description: 'This is sample content paragraph',
    },
    offlineFundDetails: [
        {
            text: 'Check',
        },
    ],
};

// Exchange
export const REVIEW_EXCHANGE_CONFIRM_DATA = [
    {
        cardId: 'tradeType',
        cardHeading: TRADE_TYPE_TEXT,
        fields: [
            {
                uniqueKey: 'tradetype',
                key: 'TradeType',
                value: 'Exchange',
                type: 'staticfield',
            },
        ],
    },
    {
        cardId: 'accountSelection',
        cardHeading: 'Account Selection',
        fields: [
            {
                key: ACCOUNT_NAME_TEXT,
                value: 'Account Name 1',
                type: 'staticfield',
            },
            {
                key: ACCOUNT_NUMBER_TEXT,
                value: 'XXXX-XXXX-XXXX',
                type: 'staticfield',
            },
        ],
    },
    {
        cardId: 'sellingFunds',
        cardHeading: 'Selling Funds',
        fieldHeading: 'USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
        fields: [
            {
                key: 'Selling Amount',
                value: '$ 5,400',
                type: 'staticfield',
            },
        ],
    },
    {
        cardId: 'mutualFund',
        cardHeading: 'Selected Mutual Fund',
        fieldHeading: 'Aggressive Growth Fund',
        fields: [],
    },
];

export const REVIEW_EXCHANGE_CONFIRM_DATA_WITH_DIVIDENDS = [
    {
        cardId: 'tradeType',
        cardHeading: TRADE_TYPE_TEXT,
        fields: [
            {
                uniqueKey: 'tradetype',
                key: 'TradeType',
                value: 'Exchange',
                type: 'staticfield',
            },
        ],
    },
    {
        cardId: 'accountSelection',
        cardHeading: 'Account Selection',
        fields: [
            {
                key: ACCOUNT_NAME_TEXT,
                value: 'Account Name 1',
                type: 'staticfield',
            },
            {
                key: ACCOUNT_NUMBER_TEXT,
                value: 'XXXX-XXXX-XXXX',
                type: 'staticfield',
            },
        ],
    },
    {
        cardId: 'sellingFunds',
        cardHeading: 'Selling Funds',
        fieldHeading: 'USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
        fields: [
            {
                key: 'Selling Amount',
                value: '$ 5,400',
                type: 'staticfield',
            },
        ],
    },
    {
        cardId: 'mutualFund',
        cardHeading: 'Selected Mutual Fund',
        fieldHeading: 'Aggressive Growth Fund',
        fields: [],
    },
    {
        cardId: 'dividends',
        cardHeading: 'Dividends and Capital Gains Preferences',
        fields: [
            {
                key: 'Reinvest Earnings, Income and Capital Gains',
                value: 'No, I do not Reinvest',
                type: 'staticfield',
            },
        ],
    },
];

export const PERCENTAGE_SYMBOL = '%';
export const TAX_WITHHOLD_TITILE = 'Tax Withholding Options';
export const AMOUNT_LABEL = 'Amount';
export const FEDERAL_TAX_LABEL = 'Federal Tax [%]';
export const STATE_TAX_LABEL = 'State Tax [%]';
export const STATE_TAX_ON_FEDTAX_TEXT = 'State Tax [%] of Federal Tax';
export const TOTAL_TAXES_TO_WITHHOLD_LABEL = 'Total Taxes to be withheld';
export const TOTAL_RECEIVED_LABEL = 'Total you will receive';
export const TOTAL_WITHDRAWAL = 'Total Withdrawal';
export const WITHHOLDING_OPTIONS_TEXT = 'Select your State Tax withholding options :';
export const DO_NOT_WITHHOLD_TAX_TEXT = 'Do not withhold Taxes';
export const WITHHOLD_TAX_TEXT = 'Withhold Taxes';
export const FEDTAX_WARNING = 'Federal tax withholding if entered must not be less than 10%';
export const TAX_WITHHOLD_WARNING = 'Total tax withholding must be less than 99%.';
export const GROUP5_TAX_WARNING_MSG = 'To change the State Tax, please call MSR';
export const STATE_TAX_INPUT_NAME = 'stateTaxPercent';
export const FEDERAL_TAX_INPUT_NAME = 'FederalTaxPercent';
export const DIST_AMOUNT_INPUT_NAME = 'DistributionAmount';
export const RADIO_BUTTON_TYPE = 'radio';
export const STATE_TAX_MIN_VALUE_WARNING_MSG = 'State Tax must be at least ';
export const STATE_TAX_MIN_VALUE_WARNING_MSG_GROUP2B_MS =
    'For premature distribution for state MS, minimum state tax is ';
export const STATE_TAX_MIN_VALUE_WARNING_MSG_GROUP2B_DC =
    'As you plan to liquidate all the funds in the current financial year, recommended state tax % is ';
// export const TRADING_MSG_REVIEW_WARNING =
//     'Online trading will cease 30 minutes prior to fund close. Trade submitted after online cutoff time will be priced as of next business day.';

export const TRADING_MSG_REVIEW_WARNING =
    'Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at 4:00 pm ET. You must place your order prior to 3:59 pm ET to be sure your order gets today’s Net Asset Value';

export const DOCUMENTS_TO_REVIEW_TEXT = 'Documents to Review';
export const ADOBE_READER_TEXT_1 = `You'll need the free`;
export const ADOBE_READER_LINK = ' Adobe Reader ';
export const ADOBE_READER_TEXT_2 = 'to view the documents';
export const ADOBE_READER_DOWNLOAD_LINK = 'https://get.adobe.com/reader/otherversions/';
export const PROSPECTUS_TEXT = 'Prospectus';
export const DISCLOSURE_STATEMENT_TEXT = 'Disclosure Statement';
export const PROSPECTUS_REVIEW_DETAILS_TEXT =
    'Please review the prospectus for the selected fund and check the boxes to proceed';
export const DISCLOSURE_REVIEW_DETAILS_TEXT = 'Please review the disclosure statement and check the boxes to proceed';
export const PDF_TEXT = '(PDF)';
export const BUSINESS_CONTINUATION_LABEL = 'Business Continuation Disclosure Statement';
export const MONEY_MARKET_TEXT = 'money market';
export const WITH_TEXT = 'with';
export const IRA_MAX_CONTR_WARNING = 'IRA Maximum Contribution Warning';
export const IRA_MAX_CONTR_MESSAGE = 'IRA Maximum Contribution Message';
export const MONTHLY_TEXT = ' monthly';
export const BENEFICIARIES_TEXT = 'Beneficiaries';
export const BENEFICIARY_CARD_TEXT = `These beneficiaries have been added automatically based on your previous selections. Please click <span class="beneficiaryLink">HERE</span> to update these beneficiaries.`;

export const ADDRESS_CHANGE_TEXT =
    'Your account address has recently been changed. To ensure the security of your account, you will not be able to proceed with the account selected.';

export const BENEFICIARY_NAME = 'Beneficiary Name';
export const RELATIONSHIP_TO_ACC_HOLDER = 'Relationship to Account Owner';
export const DISTRIBUTION_PERCENTAGE = 'Distribution Percentage';
export const SELECT_MUTUAL_FUND_TEXT = 'Select a Mutual Fund';
export const FOREIGN_ADDRESS_TEXT =
    'Transactions are not allowed for this account. Please call a service representative for more information.';
export const ARCHER_MSA_ACCOUNT_TYPE = 'Archer MSA';
export const ARCHER_MSA_WARNING_MESSAGE =
    'If this is not a qualified distribution, your withdrawal may be subject to taxes and penalties. Please consider alternatives to withdrawing from your MSA, or consult your tax advisor about the IRS guidelines for MSA accounts. If you are withdrawing funds due to death, divorce, disability or other special circumstances please contact Victory member Service at 800-235-8396';
export const FUND_NOT_AVAIL =
    'The tax-exempt fund you have selected is not available for retirement accounts, Please select another account type or a different fund from the list.';
export const OPEN_ACC = 'To purchase the fund you selected, please open or transfer an account.';
export const DUPLICATE_BANK_ERR_MSG = 'This bank account has been added previously.';
export const EXTERNAL_LINK_TEXT = 'External Website. Opens New Window';
export const TAX_ACCOUNTING_METHOD_ERROR_MSG1 = 'Your tax accounting method is ';
export const TAX_ACCOUNTING_METHOD_ERROR_MSG2 =
    '. Please contact a Member Service Representative (800) 235-8396 to place your order.';

export const TRX_MSR_LIMIT = 100000;
export const SEVEN_DAY_BANK_RULE_WARNING_MESSAGE = 'Bank added within last seven days.';
//export const VALIDATE_TEX_MSR_WARNING_MESSAGE = 'Your transaction is over the daily Limit.';
export const VALIDATE_TEXT_MSR_WARNING_MESSAGE_ATTENTION = 'Attention!';
export const VALIDATE_TEXT_MSR_WARNING_MESSAGE_ATTENTION_STAR = '*Attention:*';
export const VALIDATE_TEXT_MSR_WARNING_MESSAGE= 'Manager approval required.  Please follow the';
export const VALIDATE_TEXT_MSR_WARNING_MESSAGE2 = 'in Knowledge.';
export const VALIDATE_TEXT_MSR_WARNING_MESSAGE_LINK_TEXT= 'override process';
//export const OVERRIDE_PROCESS_URL= "https://vcm.lightning.force.com/lightning/r/Knowledge__kav/ka03r000000kM6SAAU/view";
export const OVERRIDE_PROCESS_URL= envConfig.TRANSACTION_LIMIT_OVERRIDE_PROCESS_URL;

export const STATE_TAX_PERCENT_MESSAGE_FOR_MI_CT = 'State tax must be more than or equal to ';
export const PROPORTIANTE_TRANSACTION_ERROR_MESSAGE = 'One or more trades failed to sent to FISTA, please review the failed trades below and resubmit.'
export const PROPORTIONATE_TITLE='Proportionate Transaction'
export const PROPORTIONATE_SELL = 'Withdrawing cash proportionately from a mutual fund portfolio involves taking out funds from each investment in line with their existing percentages. Instead of liquidating an entire fund, this strategy maintains the relative distribution of assets within the portfolio. By withdrawing cash proportionately, investors can meet their liquidity needs while preserving the original balance of investments in the different funds.'