export const bootstrapEscapeHatchClass = 'escape-hatch-class';

export const vcmDisclaimer = 'This message may contain information that is confidential or privileged. If you are not the intended recipient, please notify the sender immediately and destroy this email. Please see [Victory’s Privacy Policy](https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.vcm.com%2Fdocs%2Fdefault-source%2Fdefault-document-library%2Fvictory-privacy-statement.pdf%3Fsfvrsn%3D51f47c0_10&data=02%7C01%7CSubhro.Bhattacharjee%40cognizant.com%7Cc03ffa4369c74cd317a908d8396bba5e%7Cde08c40719b9427d9fe8edf254300ca7%7C0%7C0%7C637322482589704572&sdata=gASUmoQN%2Bu69%2BNh8ZGrgrasyQYqJVzwNt3d2av2DHIA%3D&reserved=0). Associate of Victory Capital Services, Inc. For current mutual fund and ETF prospectuses or the Plan Description visit vcm.com/prospectus. Please visit vcm.com/email_disclosure for further information.'

export const chModalData = {
    chatWaitTimeStaticText: 'Estimated Wait Time:',
}

export const icModalData = {
    headerData: {
        titleBarText: 'Live Chat',
        salutation: 'Hey',
        subheadingText: 'Welcome to Live Chat',
    },
    bodyData: {
        buttonText: 'Start Chat',
        queryText: 'Select Type of Query',
    },
};

export const nsModalData = {
    TITLE: 'Live Chat',
    SALUTATION: 'Hi ',
    COMMA: ',',
    PARAGRAPH_1: 'We are experiencing high traffic on our live chat channel and all member representatives are currently busy.',
    PARAGRAPH_2: 'Please try again after some time.',
};

export const ssModalData = {
    headerData: {
        titleText: 'Need Assistance?',
        icModal: {
            id: 'ic-modal',
            message: 'Live Chat',
        },
        secureMessage: {
            id: 'secure-message',
            message: 'Send us a Secure Message',
        },
    },
    bodyData: {
        customerContactNumbers: [
            {
                callInfoId: 'contact-number-info-1',
                callType: 'Toll-Free',
                phoneNumber: '800-235-8396',
            },
            {
                callInfoId: 'contact-number-info-2',
                callType: 'Local',
                phoneNumber: '979-500-3601',
            },
        ],
        availabilityStatus: {
            phoneQueueWaitTimeText: 'Current Phone wait time: ',
        },
    },
};

export const ssModalFooterData = {
    COBROWSING: {
        LINK_TEXT: 'Enable Co-Browsing',
        MESSAGE: 'Talking to a representative?',
    },
};

export const tsModalData = {
    titleBarText: 'Testing Modal',
};

export const vcModalData = {
    titleBarText: 'Co-Browsing',
    advisoryText: 'You will have to be on the phone with Victory Capital Representative to use this',
};

export const wtModalData = {
    securityMessage:
        'For your security, please do not provide sensitive information such as your password, or Social Security number.',
    waitTimeMsgStaticText: 'Your estimated wait time is',
    waitTimeUnits: 'seconds',
};
