import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import moment from 'moment-timezone';
import { assignObj } from 'utils';
import SupportServicesCard from '../../../ChatCobrowsingModule/SupportServicesModal/SupportServicesCard';


const S = {};

S.ModalBodyContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
`;

const ContactContent = ({ hours }) => {
    const { startTime,endTime,hoursAvailable } = hours || null;
  //  let timeZone = '';
    // let formattedStartTime=''
    // let formattedEndTime=''
    // if ( startTime && endTime  ) {
    //     // timeZone = moment.tz(moment.tz.guess()).format('z');        
    //   //  const todayDate = moment().format('YYYY-MM-DD');
    // //    timeZone = moment.tz(todayDate, 'America/Chicago').format('z'); 
    //     formattedStartTime=moment(new Date(startTime)).format("hh:mm A");
    //     formattedEndTime=moment(new Date(endTime)).format("hh:mm A");
    // }
    const servicerName = 'Victory Capital Member Services';
    const availabilities = assignObj( [
        {
            days: 'Monday - Friday',
            hours: hoursAvailable // `${formattedStartTime} - ${formattedEndTime} CT`
        }
    ])
    return (
        <S.ModalBodyContainer data-test="contactcontent">
            {startTime && endTime && <SupportServicesCard serviceHours={availabilities} titleText={servicerName} />}
        </S.ModalBodyContainer>
    );
};

ContactContent.propTypes = {
    hours: PropTypes.instanceOf(Object),
};

ContactContent.defaultProps = {
    hours: {},
};

export default ContactContent;
