// README - this component is no longer part of the Live Chat modal but it is imported by
// dashboard contact center.
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ClockIcon } from '../ChatAssets';

const S = {};

S.CardContainer = styled.div`
    background: #f5f5f5 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 20px 15px 20px 20px;
    margin-bottom: 20px;
    height:115px;
`;

S.CardTitle = styled.span`
    align-self: flex-start;
    font-size: 1rem;
    line-height:1.25rem;
    font-weight: 600;
    font-family: benton-sans;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 19px;
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
        margin-bottom: 5px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
        margin-bottom: 5px;
    }
`;

S.CardRowContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 100%;
`;

S.AvailabilityContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
`;

S.ClockIcon = styled(ClockIcon)`
    background: transparent url('img/Icon_remainder.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-right:10px;
    margin-top:10px;
    svg {
        background-size: 20px 20px;
    }
`;

S.DaysText = styled.span`
    align-self: flex-start;
    font-family: benton-sans;
    font-size: 0.875rem;
    font-weight: 605;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
`;

S.HoursText = styled.span`
    align-self: flex-start;
    font-family: benton-sans;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom:20px;
`;

S.TitleContainer = styled.span`
    display: flex;
`;

S.StyledIcon = styled(ClockIcon)`
    margin-right: 10px;
    svg {
        background-size: 20px 20px;
    }
`;

const SupportServicesCard = ({ titleText, serviceHours, hasIconNearTitle }) => {
    return (
        <S.CardContainer>
            {hasIconNearTitle ? (
                <S.TitleContainer>
                    <S.StyledIcon focusable="false" />
                    <S.CardTitle as="h3">{titleText}</S.CardTitle>
                </S.TitleContainer>
            ): <S.CardTitle as="h3">{titleText}</S.CardTitle>}
            
            {serviceHours.map((availability, index) => (
                <S.CardRowContainer key={index.toString()}>
                    {!hasIconNearTitle && <S.ClockIcon focusable="false" />}
                    <S.AvailabilityContainer>
                        <S.DaysText aria-hidden="true">{availability.days}</S.DaysText>
                        <span className="sr-only">{availability.days.replace('-','To')}</span>
                        <S.HoursText aria-hidden="true">{availability.hours}</S.HoursText>
                        <span className="sr-only">{availability.hours.replace('-','To')}</span>
                    </S.AvailabilityContainer>
                </S.CardRowContainer>
            ))}
        </S.CardContainer>
    );
};

SupportServicesCard.propTypes = {
    serviceHours: PropTypes.arrayOf(
        PropTypes.shape({
            days: PropTypes.string.isRequired,
            hours: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    titleText: PropTypes.string.isRequired,
    hasIconNearTitle: PropTypes.bool
};

export default SupportServicesCard;
