import './UserValidationHelper';

const getValidValueOf = (inputValCheck, successValue, defaultValue='') =>
{
    return inputValCheck ? successValue : defaultValue
}

const getValidValue = (strValue,defaultValue='') =>
{
    return strValue ? strValue : defaultValue
}

const isValidValue = (inputVal) => {

    return inputVal ? true : false
}


const isRoleNotGuestAndProspect = (role) => {
    return role !== 'Guest' && role !== 'Prospect'
}
const isHaveSpouse = (arrayData,findOutValue) =>
{
    const filterData = arrayData?.filter((item)=> item?.relationToAccHolder == findOutValue);
    return filterData?.length > 0 ? true : false;
}

const getDataFromANDCondition = (value1,value2,value3="") =>
{
    if(value3 =="")
    {
        return (value1 && value2);
    }
    else
    {
        return (value1 && value2 && value3);
    }
}

const checkORConditionWithParameter = (value1,value2,value3,value4) =>
{
    return (value1 == value2 || value3 == value4)
}

const checkANDConditionWithParameter = (value1,value2,value3,value4) =>
{
    return (value1 == value2 && value3 == value4)
}

const accountIdentification = (dataObj,accountType) =>
{
   return (dataObj?.getEnabledFeature?.features == undefined) ? false : dataObj.getEnabledFeature?.features.includes(accountType);
}

const isOldDashbord = (dataObj,accountType) =>
{
   return (dataObj?.getEnabledFeature?.features !== undefined && !dataObj.getEnabledFeature?.features.includes(accountType));
}

const getDataFromThreeOrCondition = (value1,value2,value3) =>
{
    return (value1 || value2 || value3);
}

const checkSingleEqualityAndReturnValue = (op1,op2,returnValue1,returnValue2)=>
{
    return (op1==op2 ? returnValue1 : returnValue2);
}

const checkArrayLengthAndReturnValue = (arrayData,lengthValue,value1,value2)=>
{
    return (arrayData?.length > lengthValue ? value1 : value2)
}

const identifyIsProportionate =(arrayObj)=>{
    const filterArray = arrayObj.filter((item)=> item?.showProportionate == true)
    return filterArray?.length > 0 ? true : false;
}

const identifyIsProportionateFundAvailable =(arrayObj)=>{
    if(arrayObj.length > 0){
        const isProportionateFundAvailable = arrayObj.some(item=> item.fundName === 'Proportionate' && item?.showProportionate )
        return !isProportionateFundAvailable;
    }
   
    return false
}

const identifyExsitingAccount =(arrayObj)=>{
    let count = 0;
    let tempArrayobj = JSON.parse(JSON.stringify(arrayObj));
    for(let i=0; i<tempArrayobj.length; i++){
        if(tempArrayobj[i]?.fundAccountNumber){
            count++;
        }
    }
    if(count > 1){
        return true
    }
    else{
       return false
    }
}
const addNewElementIntoArray = (arrayObj)=>{
    let index =0;
    let tempArrayobj = JSON.parse(JSON.stringify(arrayObj));
    if(identifyExsitingAccount(tempArrayobj) && identifyIsProportionate(tempArrayobj)== false ){
        for(let i=0; i<tempArrayobj.length; i++){
            if(tempArrayobj[i]?.fundAccountNumber){
                index = i;
            }
        }
        tempArrayobj.splice(index+1,0,{showProportionate:true,fundName:'Proportionate',
        fundOptionSelected:"sub",
        minimumAutomaticInvestment:"50.000000000000"
        })
        return tempArrayobj
    }
    return tempArrayobj
}

const addNewElementIntoSellFunds =(arrayObj)=>{
    let tempArrayobj = JSON.parse(JSON.stringify(arrayObj));
    if(tempArrayobj?.length > 1 && identifyIsProportionate(tempArrayobj)== false){
        tempArrayobj.push({showProportionate:true,fundName:'Proportionate',
        userinputamt:"",
        liquidityNoticeFlag:false,
        redemptionNoticeFlag:false,
        fundNumber:"ProportionateFund",
        accountType:"",
        accountValue:"",
        availableShares:"",
        changeInNav:"",
        changeInNavPercentage:"",
        costBasis:"Average",
        currentAccountBalance:"",
        eschowShare:"",
        fundAccountNumber:"",
        fundType: "Target Retirement",
        imposedFee:"NA",
        initialInvestment:"",
        lastNav:"",
        max52W:"",
        min52W:"",
        minimumAutomaticInvestment:"",
        minimumInitialAnnuallyInvestment:"",
        minimumInitialBiweeklyInvestment:"",
        minimumInitialMonthlyInvestment:"",
        minimumInitialQuarterlyInvestment:"",
        nav:"",
        netAsset:"",
        pendShrTot:"",
        risk:"Moderate",
        socialCode:"1",
        symbol: "URTRX",
        unissShrBal: "",
        unissuedBalance: "",
        unissuedShares: "",
        amtType: ""
        })
    }
    return tempArrayobj
}


const removeProportionateFund = (arrayObj) =>{
    let tempArrayobj = JSON.parse(JSON.stringify(arrayObj));

    if(identifyIsProportionate(tempArrayobj))
    {
        tempArrayobj.pop();
        console.log('tempArrayobj',tempArrayobj)
        return tempArrayobj;
    }

    return tempArrayobj;
}

export {
    getValidValueOf,
    getValidValue,
    isValidValue,
    isRoleNotGuestAndProspect,
    getDataFromANDCondition,
    checkORConditionWithParameter,
    checkANDConditionWithParameter,
    accountIdentification,
    isOldDashbord,
    getDataFromThreeOrCondition,
    checkSingleEqualityAndReturnValue,
    checkArrayLengthAndReturnValue,
    isHaveSpouse,
    addNewElementIntoArray,
    identifyIsProportionate,
    identifyExsitingAccount,
    addNewElementIntoSellFunds,
    identifyIsProportionateFundAvailable,
    removeProportionateFund
}