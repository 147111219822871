import IconCall from 'assets/Icon_call.svg';
import React, { useCallback } from 'react';
import { ssModalData } from '../../ChatCobrowsingModule/ChatConstants';
import styled from 'styled-components';
import './MobileContactCard.css';

const CallusStyle = styled.span`
    font-weight: 600;
    display: inline-block;
`;

const TollFreeLink = styled.a`
    color: #004A98;
    font-size: 1rem;
`;

const FOR_CUSTOMERS_TEXT = 'For U.S. Customers';
const MSR_NUMBER = '1 800-235-8396';
const TOLL_FREE_TEXT = 'International toll free numbers';

const MobileContactCard = () => {
    const {
        bodyData: { customerContactNumbers },
    } = ssModalData;
    const { TollNumberLink } = customerContactNumbers[0];

    const onTollFreeClick = useCallback(e => {
        e.stopPropagation();
    }, []);

    return (
        <>
            <hr />
            <div className="imagesContainer">
                <img className="imgIconCall" alt="" src={IconCall} />
                <CallusStyle>Call Us</CallusStyle>
            </div>
            <div className="vcm-toll-phone-container">
                <div className="labelForPhone">{FOR_CUSTOMERS_TEXT}</div>
                <div className="literalPhoneNum">{MSR_NUMBER}</div>
                <div>
                    <TollFreeLink href={TollNumberLink} className="tollFreeLink" onClick={onTollFreeClick}>
                        {TOLL_FREE_TEXT}
                    </TollFreeLink>
                </div>
            </div>
        </>
    );
}

export default MobileContactCard;