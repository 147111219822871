import consts from './consts';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import MobileContactCard from './MobileContactCard';
import PropTypes from 'prop-types';
import React,{useState} from 'react';
import { Row, Col, ToggleButton } from 'react-bootstrap';
import styled from 'styled-components';
import { WIcon } from '../../../common';
import './MarketAccountTypeCard.css';
import { Note_Text } from './consts';
import PrintPdf from '../../../common/TermsAndConditions/PrintPdf';
import { MARKET_NAO_ACCOUNT_TYPES } from '../consts';
import envConfig from 'env/EnvConfig';

const CustomDiv = styled.div `
    label  input[type='radio']{
        position: absolute;
        width: 100%;
        left:0;
        top:0.5px;
        z-index: -1;
    }
    label button{
        margin-bottom: 0;
        padding: 0;
    }   
    >label{
        padding: 0;
        margin: 0;
    }
}`;

const assignObj = obj => {
    return obj;
};

const usa_patriot_pdf = {
    url: envConfig.TERMS_PDF_USA_PATRIOT_ACT,
    withCredentials: true,
}
const MarketAccountTypeCard = ({ item, onclick, cardClassName }) => {
    const cardClsName = `detailedCradWrapper ${cardClassName}`;
    const checked = cardClassName === 'selected';
    const [printPdf, setPrintPdf] = useState(false)
    const [pdfFile, setpdfFile] = useState(null)
    const isSpecialtySubtype = () => {
        const key = item.key.toLowerCase();
        switch (key) {
            case '403b':
            case 'bene_ira':
            case 'biz_org':
            case 'estate':
            case 'guard_ira':
            case 'opt_ret_program':
            case 'roth_conv':
            case 'transfer_on_death':
            case 'trust':
                return true;
            default:
                return false;
        }
    }

    const getCorrectDetails = () => {
        switch (item.value) {
            case `Traditional IRA`:
            case `Roth IRA`:
            case `Simple IRA`:
            case `SEP IRA`:
            case `403B`:
            case `Beneficiary IRA`:
            case `Guardian IRA`:
            case `Optional Retirement Program (ORP)`:
            case `Transfer on Death`:
            case `Roth Conversion IRA`:
                return ( <>
                        <li> { consts.customizedStatments.iRAs } </li> 
                        <li> { consts.detailsNeededlist[1].value } </li>
                    </>
                )
            case `Individual`:
                return ( <>
                    <li> { consts.customizedStatments.indyAcct } </li>
                    <li> { consts.detailsNeededlist[1].value } </li>
                    </>
                )
            case `Joint Tenants With Right of Survivorship`:
            case `Joint Tenants in Common`:
            case `Joint Tenants in Entirety`:
                return ( <>
                    <li> { consts.customizedStatments.jointAcct } </li>
                    <li> { consts.detailsNeededlist[1].value } </li>
                    </>
                )
            case `529 Education Savings Plan`:
            case `Uniform Gifts/Transfers to Minors(UGMA/UTMA)`:
                return ( <>
                    <li > { consts.customizedStatments.ugmaUtma529 } </li> 
                    <li> { consts.detailsNeededlist[1].value } </li> 
                    </>
                )
            case `Business/Organization`:
            case `Estate`:
            case `Trust`:
                return ( <>
                    <li> { consts.customizedStatments.specAccts } </li>
                    <li> { consts.detailsNeededlist[1].value } </li>
                    </>
                )
            case `Rollover IRA`:
                    return ( <>
                        <li > { consts.customizedStatments.ugmaUtma529 } </li> 
                        </>
                    )
            default:
                return <li> { consts.detailsNeededlist[0].value } </li>;
        }
    }

    let finalClass = cardClsName;
    if (item.key === 'sep') {
        finalClass = `${finalClass} reduce-top-of-sep-ira`
    }
  
    const printPdfFunction = () => {
        setPrintPdf(true)
        setpdfFile(usa_patriot_pdf)
    }
    const PrintPdfHide=()=>{
        setPrintPdf(false)
    }
    return ( <>
        <div className = { finalClass } id="NAOAccountType"
            onClick = { onclick }
            data-key = { item.key }
            role = "radio"
            tabIndex = "0">
            <Row className = "headerRow">
                <CustomDiv tabIndex="-1">
                    <ToggleButton key = { "1" }
                        type = "radio"
                        variant = "secondary"
                        name = "radio"
                        style = {
                            assignObj({
                                width: '100%',
                                backgroundColor: '#FFFFFF',
                                boder: 0
                            })
                        }
                        value = { item.value }
                        onChange = { onclick }
                        checked = { checked }
                        aria-checked = { checked }
                        className = "btn btn-outline-light" >
                        <span className="cardheader" role="radio"> { item.value === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT ? MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT_FULL_FORM : item.value } </span> 
                    </ToggleButton>
                </CustomDiv>
                <div tabIndex = "-1" > 
                    <div className='gt-767' >
                    <div style={{display:'flex', flexDirection: 'row', justifyContent:'space-between'}}>
                            <div className="completionTxtSty">
                            { consts.timeEsitmateText(item.key) } 
                            </div>
                            {
                                cardClassName === 'selected' && ( 
                                    <WIcon style={{marginLeft:'35px'}} icon = { faCheckCircle }
                                        fontawesomestyle = { consts.fontawesomeiconcard }
                                    />
                                )
                            }
                        </div>
                    </div>

                    <div className='lt-767' > 
                        <div style={{display:'flex', flexDirection: 'row', justifyContent:'space-between'}}>
                            <div className="completionTxtSty">
                                { consts.timeEsitmateTextLT767(item.key) }
                            </div>
                            {
                                cardClassName === 'selected' && ( 
                                    <WIcon icon = { faCheckCircle }
                                        fontawesomestyle = { consts.fontawesomeiconcard }
                                    />
                                )
                            }
                        </div>
                    </div>
                </div> 
            </Row>
            <Row className = "descriptionRow" >
                <Col className = "descriptionCol" > 
                    { item.description }
                </Col> 
                <Col className = "descriptionCol descriptionList" >
                    <span > { consts.detailsInfotext } </span> 
                    <ul className = "detailsList" > { getCorrectDetails() }</ul> 
                    <p className='noteText'>{Note_Text} <span onClick={printPdfFunction} className="BlueTextInAccountType"> USA PATRIOT Act</span></p> 
                    <PrintPdf
                        file={pdfFile}
                        show={printPdf}
                        handleHide={PrintPdfHide}
                        maximumModalHeight={window.innerHeight}
                    />
                </Col>

                <Col className="showOnMobile" style={{paddingLeft:'0'}}>
                    { isSpecialtySubtype() && <MobileContactCard/> }
                </Col>
            </Row> 
        </div>
        </>
        );
};

MarketAccountTypeCard.propTypes = {
    item: PropTypes.instanceOf(Object),
    onclick: PropTypes.func,
    cardClassName: PropTypes.string,
};

MarketAccountTypeCard.defaultProps = {
    item: {},
    onclick: () => {},
    cardClassName: '',
};
export default MarketAccountTypeCard;