import React from "react";
const documentDateText = 'Document Date';
const accountNameText = 'Account Name';
export const sortByDateText = 'sortBy';
export const SORT_BY_TEXT = 'Sort by';
export const dateFormatText = 'MM/DD/YYYY';
export const DOCUMENT_TYPE_TEXT = 'documentCategory';
export const ACCOUNT_TEXT = 'Account';
export const VIEWABLE_TEXT = 'Viewable';
export const DOCUMENT_TEXT = 'Document Category';
export const YEAR_TEXT = 'Year';
export const VCM_CODE_TEXT = 'VCM form code';
export const DOCUMENT_CATEGORY = 'Document Category';
export const DOCUMENT_SOURCE = 'Document Source';
export const CREATION_START_DATE = 'Creation Start Date';
export const CREATION_END_DATE = 'Creation End Date';
export const VCM_ID_ERROR_MESSAGE = 'VCM ID allows for maximum 9 digits. Please review your input.';
export const USSA_MEMBER_ID_ERROR_MESSAGE = 'USAA member ID allows for maximum 9 digits. Please review your input.';
export const VCM_DOCUMENT_FORM_CODE = [
    { key: "Select", value: "Select" },
    { key: "98316", value: "98316 - 529 Custodian Change Form" },
    { key: "47548", value: "47548 - 529 Incoming Transfer Form" },
    { key: "47546", value: "47546 - 529 Change of Beneficiary Form" },
    { key: "47549", value: "47549 - 529 Account Change Form/529 Successor Custodian account for non UGMA/UTMA" },
    { key: "47551", value: "47551 - 529 Investment Change Form" },
    { key: "98315", value: "98315 - 529 3rd Party Automatic Investment Plan Form" },
    { key: "47901", value: "47901 - 529 Payroll Deduction Form" },
    { key: "47547", value: "47547 - 529 Distribution Form" },
    { key: "47550", value: "47550 - 529 Successor Custodian account owner for UGMA/UTMA" },
    { key: "98631", value: "98631 - 529 Rollover Form" },
    { key: "47545", value: "47545 - 529 Account Application" },
    { key: "15550", value: "15550 - Checkwriting Order Form" },
    { key: "99268", value: "99268 - UTMA/UGMA Account Application" },
    { key: "99267", value: "99267 - Non Retirement Account Application" },
    { key: "134794", value: "134794 - 403b Custodial Agreement" },
    { key: "99532", value: "99532 - Account Transfer Form" },
    { key: "99460", value: "99460 - Coverdell Distribution Form" },
    { key: "99457", value: "99457 - Automatic Withdrawal Plan Form" },
    { key: "99468", value: "99468 - Cash Withdrawal From Non Retirement Mutual Fund" },
    { key: "134595", value: "134595 - Certification of Beneficial Owners" },
    { key: "29164", value: "29164 - Incoming Bank Wire" },
    { key: "42204", value: "42204 - Family Of Funds Non Retirement Accounts" },
    { key: "99473", value: "99473 - One and Same Form" },
    { key: "15360", value: "15360 - Registration Change Form" },
    { key: "99470", value: "99470 - IRA Minor Addendum" },
    { key: "99472", value: "99472 - Successor Custodian For UTMA/UGMA Form" },
    { key: "99539", value: "99539 - Victory Power of Attorney Form" },
    { key: "99606", value: "99606 - Statement of Households" },
    { key: "99466", value: "99466 - EFT Authorization" },
    { key: "99467", value: "99467 - Gifting Of Securities" },
    { key: "99459", value: "99459 - Automatic Investment Plan Form" },
    { key: "26300", value: "26300 - 403(B)(7) Account Application" },
    { key: "99465", value: "99465 - Correction of Excess Form" },
    { key: "99475", value: "99475 - New Bus Entity/Organization Account Req Docs List" },
    { key: "47772", value: "47772 - Estate Account Application" },
    { key: "47773", value: "47773 - Trust Account Application" },
    { key: "94207", value: "94207 - Business/Organization Application" },
    { key: "42203", value: "42203 - Family Of Funds Retirement Accounts" },
    { key: "99461", value: "99461 - RMD Form" },
    { key: "99464", value: "99464 - IRA Periodic Distribution Form" },
    { key: "99463", value: "99463 - IRA Recharacterization Form" },
    { key: "134790", value: "134790 - Traditional/SEP IRA Custodial Agreement" },
    { key: "99462", value: "99462 - Roth Conversion Form" },
    { key: "134795", value: "134795 - Roth IRA Custodial Agreement" },
    { key: "134793", value: "134793 - SIMPLE Custodial Agreement" },
    { key: "99471", value: "99471 - IRA Beneficiary Form" },
    { key: "26258", value: "26258 - ORP Account Application" },
    { key: "99266T", value: "99266T - Traditional SEP IRA Account Application" },
    { key: "99266S", value: "99266S - SIMPLE IRA Account Application" },
    { key: "98102T", value: "98102T - Inherited IRA Account Application for Individuals" },
    { key: "98103T", value: "98103T - Inherited Traditional IRA Account Application For Trust/Entity Or Traditional" },
    { key: "98102R", value: "98102R - Inherited Roth IRA Account Application For Individuals" },
    { key: "99266R", value: "99266R - Roth IRA Account Application" },
    { key: "99458", value: "99458 - IRA One Time Distribution Form" },
    { key: "98146", value: "98146 - TOD Beneficiary Form" },
    { key: "99531", value: "99531 - Direct Rollover Instructions" },
    { key: "98446", value: "98446 - Add/Remove Trustee Form" }
  ]

export const DOCUMENT_CATEGORY_LIST= [
    { key: "Select", value: "Select" },
    { key: "Account_Maintenance", value: "Account_Maintenance" },
    { key: "Account_Opening", value: "Account_Opening" },
    { key: "Account_Servicing", value: "Account_Servicing" },
    { key: "Client_Provided", value: "Client_Provided" },
    { key: "Confirm", value: "Confirm" },
    { key: "Fund_Literature", value: "Fund_Literature" },
    { key: "General_Correspondence", value: "General_Correspondence" },
    { key: "Payment", value: "Payment" },
    { key: "Portfolio_Planner_Recommendation", value: "Portfolio_Planner_Recommendation" },
    { key: "Proxy", value: "Proxy" },
    { key: "RMD_Advice", value: "RMD_Advice" },
    { key: "Statement", value: "Statement" },
    { key: "Tax", value: "Tax" },
    { key: "Financial_Planning", value: "Financial_Planning" },
    { key: "Knowledge_Base", value: "Knowledge_Base" },
    { key: "Marketing", value: "Marketing" },
    { key: "Operating_Procedure", value: "Operating_Procedure" },
    { key: "Other", value: "Other" },
    { key: "Policy", value: "Policy" },
    { key: "USAA_Historical_Record", value: "USAA_Historical_Record" },
    { key: "Legal_Compliance", value: "Legal_Compliance" }
  ]
export const DOCUMENT_SOURCE_LIST=[
    { key: "Select", value: "Select" },
    { key: "Albridge", value: "Albridge" },
    { key: "Alfresco", value: "Alfresco" },
    { key: "Broadridge", value: "Broadridge" },
    { key: "EOS", value: "EOS" },
    { key: "ODOC", value: "ODOC" },
    { key: "Toppan Merrill", value: "Toppan Merrill" }
  ]

 export const yearListStrDataGrid= [
    "2023",
    "2022",
    "2021",
    "2020",
    "2019 and earlier",
];
export const PREVIOUS_YEAR = 2022;
export const INSTRUCTIONS_TEXT =
    'To access your statements, confirmations, tax documents, account updates, and general documents, please select the year and document category. Note: Before November 9, 2020, document categories include statements, confirmations, and tax documents only.';
export const filterDisclosure =
    '* We are working to provide access to your historical documents. If you need a copy of a mutual fund document, please send us a request through your secure message center.';
export default {
    documentsData: [
        {
            DocumentTitle: 'IMCO Brokerage 2020 Tax Doc 1564 XXXX29852',
            Account: {
                AccountNameText: accountNameText,
                AccountNameValue: 'ROTH IRA - XXX45845',
            },
            DocumentDate: {
                DocumentDateText: documentDateText,
                DocumentDateValue: '11/15/2019',
            },
            Category: {
                CategoryText: 'Category',
                CategoryValue: 'Statements',
            },
            Status: {
                StatusText: 'Status',
                StatusValue: '_',
            },
        },
        {
            DocumentTitle: 'IMCO Brokerage 2020 Tax Doc 6452 XXXX24522',
            Account: {
                AccountNameText: accountNameText,
                AccountNameValue: 'Traditional IRA - XXX96245',
            },
            DocumentDate: {
                DocumentDateText: documentDateText,
                DocumentDateValue: '01/11/2020',
            },
            Category: {
                CategoryText: 'Category',
                CategoryValue: 'Statements',
            },
            Status: {
                StatusText: 'Status',
                StatusValue: 'Draft',
            },
        },
        {
            DocumentTitle: 'IMCO Brokerage 2018 Tax Doc 3204 XXXX29999',
            Account: {
                AccountNameText: accountNameText,
                AccountNameValue: 'Retirement Funds - XXX13565',
            },
            DocumentDate: {
                DocumentDateText: documentDateText,
                DocumentDateValue: '12/25/2019',
            },
            Category: {
                CategoryText: 'Category',
                CategoryValue: 'Statements',
            },
            Status: {
                StatusText: 'Status',
                StatusValue: 'Submitted',
            },
        },
        {
            DocumentTitle: 'IMCO Brokerage 2015 Tax Doc 5894 XXXX278502',
            Account: {
                AccountNameText: accountNameText,
                AccountNameValue: 'ROTH IRA - XXX96525',
            },
            DocumentDate: {
                DocumentDateText: documentDateText,
                DocumentDateValue: '09/16/2019',
            },
            Category: {
                CategoryText: 'Category',
                CategoryValue: 'Statements',
            },
            Status: {
                StatusText: 'Status',
                StatusValue: '_',
            },
        },
        {
            DocumentTitle: 'IMCO Brokerage 2019 Tax Doc 5642 XXXX12602',
            Account: {
                AccountNameText: accountNameText,
                AccountNameValue: 'ROTH IRA - XXX18525',
            },
            DocumentDate: {
                DocumentDateText: documentDateText,
                DocumentDateValue: '01/19/2020',
            },
            Category: {
                CategoryText: 'Category',
                CategoryValue: 'Statements',
            },
            Status: {
                StatusText: 'Status',
                StatusValue: '_',
            },
        },
        {
            DocumentTitle: 'IMCO Brokerage 2016 Tax Doc 5244 XXXX8512',
            Account: {
                AccountNameText: accountNameText,
                AccountNameValue: 'UTMA Account - XXX19872',
            },
            DocumentDate: {
                DocumentDateText: documentDateText,
                DocumentDateValue: '01/17/2020',
            },
            Category: {
                CategoryText: 'Category',
                CategoryValue: 'Statements',
            },
            Status: {
                StatusText: 'Status',
                StatusValue: 'Draft',
            },
        },
        {
            DocumentTitle: 'IMCO Funds 2012 Tax Doc 5244 XXXX28506',
            Account: {
                AccountNameText: accountNameText,
                AccountNameValue: 'ROTH IRA - XXX98625',
            },
            DocumentDate: {
                DocumentDateText: documentDateText,
                DocumentDateValue: '01/26/2020',
            },
            Category: {
                CategoryText: 'Category',
                CategoryValue: 'Statements',
            },
            Status: {
                StatusText: 'Status',
                StatusValue: '_',
            },
        },
        {
            DocumentTitle: 'IMCO Brokerage 2017 Tax Doc 3654 XXXX24512',
            Account: {
                AccountNameText: accountNameText,
                AccountNameValue: 'ROTH IRA - XXX65321',
            },
            DocumentDate: {
                DocumentDateText: documentDateText,
                DocumentDateValue: '01/30/2020',
            },
            Category: {
                CategoryText: 'Category',
                CategoryValue: 'Statements',
            },
            Status: {
                StatusText: 'Status',
                StatusValue: 'Submitted',
            },
        },
        {
            DocumentTitle: 'IMCO Brokerage 2011 Tax Doc 1234 XXXX27822',
            Account: {
                AccountNameText: accountNameText,
                AccountNameValue: 'ROTH IRA - XXX96545',
            },
            DocumentDate: {
                DocumentDateText: documentDateText,
                DocumentDateValue: '01/05/2020',
            },
            Category: {
                CategoryText: 'Category',
                CategoryValue: 'Statements',
            },
            Status: {
                StatusText: 'Status',
                StatusValue: '_',
            },
        },
        {
            DocumentTitle: 'IMCO Brokerage 2015 Tax Doc 7986 XXXX481913',
            Account: {
                AccountNameText: accountNameText,
                AccountNameValue: 'ROTH IRA - XXX23542',
            },
            DocumentDate: {
                DocumentDateText: documentDateText,
                DocumentDateValue: '01/05/2017',
            },
            Category: {
                CategoryText: 'Category',
                CategoryValue: 'Statements',
            },
            Status: {
                StatusText: 'Status',
                StatusValue: '_',
            },
        },
        {
            DocumentTitle: 'FCFO Brokerage 2010 Tax Doc 9654 XXXX35421',
            Account: {
                AccountNameText: accountNameText,
                AccountNameValue: 'ROTH IRA - XXX98652',
            },
            DocumentDate: {
                DocumentDateText: documentDateText,
                DocumentDateValue: '08/05/2019',
            },
            Category: {
                CategoryText: 'Category',
                CategoryValue: 'Statements',
            },
            Status: {
                StatusText: 'Status',
                StatusValue: '_',
            },
        },
    ],

    category: [
        { value: 'All Categories', key: 'All Categories' },
        { value: 'Statements', key: 'Statements' },
        { value: 'Confirmations', key: 'Confirmations' },
        { value: 'Tax Documents', key: 'Tax Documents' },
        { value: 'Account Updates', key: 'Account Updates' },
        { value: 'General', key: 'General' },
        // { value: 'Other', key: 'Other' },
    ],

    categoryList: [
        { value: 'All Categories', key: 'All Categories' },
        { value: 'Statements', key: 'Statements' },
        { value: 'Confirmations', key: 'Confirmations' },
        { value: 'Tax Documents', key: 'Tax Documents' },
    ],

    mappedDocCategories: {
        Account_Maintenance: 'Account Updates',
        Account_Opening: 'Account Updates',
        Account_Servicing: 'Account Updates',
        Account_Maintenance_Letter: 'Account Updates',
        Portfolio_Planner_Recommendation: 'General',
        Client_Provided: 'General',
        Fund_Literature: 'General',
        General_Correspondence: 'General',
        Knowledge_Base: 'General',
        Marketing: 'General',
        Operating_Procedure: 'General',
        Other: 'General',
        Payment: 'General',
        Policy: 'General',
        Proxy: 'General',
        RMD_Advice: 'General',
        USAA_Historical_Record: 'General',
        Statement: 'Statements',
        Privacy_Notice: 'General',
        Terms_and_Condition: 'General',
        Online_Service_Agreement: 'General',
        Form_CRS: 'General',
        Legal_Compliance: 'General',
        General: 'General',
        Tax: 'Tax Documents',
        Tax_Document: 'Tax Documents',
        Trade_Confirmation: 'Confirmations',
        Confirm: 'Confirmations',
        Confirmation: 'Confirmations',
    },
    accountDescriptor: [
        { value: 'Roth IRA', key: 'RothIRA' },
        { value: 'Traditional IRA', key: 'TraditionalIRA' },
        { value: 'Retirement Funds', key: 'RetirementFunds' },
        { value: 'UTMA Account', key: 'UTMAAccount' },
    ],
    ESignHeading: 'Documents To Sign',
    ESignSubHeading: 'USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
    downloadText: 'Download',
    ESignBody:
        'This document contains the information provided by you as part of your automatic investment plan, including Terms and Conditions. By selecting "Submit", I agree to the documents and terms above and certify that any information I provided is accurate, up-to-date and complete.',
    ESignAgreeText: 'I, Agree that I have received, read, understood, and agree to the documents linked below.',
};
